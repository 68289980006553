import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft, LogIn, AlertCircle, Loader, Check, Package, BarChart, Info } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import {API_URL} from "./Settings.config";

// Helper function to generate URL-friendly slugs
export const generateSlug = (text) => {
    return text
        .toLowerCase()
        .replace(/[^\w\s-]/g, '') // Remove special characters
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Remove consecutive hyphens
        .trim();
};

const Alert = ({ children, onClose }) => (
    <div className="fixed top-16 md:top-24 right-4 left-4 md:left-auto md:w-96 z-50 bg-red-500/90 text-white p-4 rounded-none border border-red-600 animate-fadeIn">
        <div className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <div className="flex-1">{children}</div>
        </div>
    </div>
);

const ProductDetail = () => {
    const { slug, id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const host = API_URL;

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(host + `/invoice/website/product/${id}`);
                if (!response.ok) throw new Error('Product not found');
                const data = await response.json();

                // Format the product data
                const formattedProduct = {
                    id: data.id,
                    name: data.product_name,
                    category: data.product_category,
                    sku: data.product_sku,
                    volume: `${data.quantity}ML`,
                    alcPercentage: data.alc_percentage,
                    stock: data.available_stock,
                    description: data.description,
                    image: data.extra_details?.website_image_url
                        ? host + `/images/${data.extra_details.website_image_url}`
                        : host + `/images/${data.image_url}`,
                    details: data.extra_details || {}
                };

                // Verify URL matches the product
                const correctSlug = generateSlug(formattedProduct.name);
                if (correctSlug !== slug) {
                    window.location.replace(`/product/${correctSlug}/${id}`);
                    return;
                }

                setProduct(formattedProduct);

                // Fetch related products (same category)
                const relatedResponse = await fetch(host + '/invoice/website/all-products');
                const relatedData = await relatedResponse.json();
                const related = relatedData
                    .filter(p => p.product_category === data.product_category && p.id !== data.id)
                    .slice(0, 4)
                    .map(p => ({
                        id: p.id,
                        name: p.product_name,
                        category: p.product_category,
                        volume: `${p.quantity}ML`,
                        image: p.extra_details?.website_image_url
                            ? host +`/images/${p.extra_details.website_image_url}`
                            : host + `/images/${p.image_url}`
                    }));
                setRelatedProducts(related);
            } catch (err) {
                setError('Failed to load product details');
                console.error('Error fetching product:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [slug, id]);

    if (loading) {
        return (
            <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24 flex items-center justify-center">
                <div className="flex flex-col items-center gap-4 animate-fadeIn">
                    <Loader className="w-12 h-12 text-amber-300 animate-spin" />
                    <p className="text-gray-400 tracking-wider uppercase">Loading...</p>
                </div>
            </div>
        );
    }

    if (error || !product) {
        return (
            <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
                <Alert>{error || 'Product not found'}</Alert>
                <div className="container mx-auto px-4 py-12 max-w-3xl">
                    <Link
                        to="/products"
                        className="inline-flex items-center gap-2 text-amber-300 hover:text-amber-400 transition-colors"
                    >
                        <ChevronLeft size={20} />
                        <span>Back to Products</span>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`${product.name} - Cask International`}</title>
                <meta name="description" content={product.description || `${product.name} - ${product.volume} - Available at Cask International`} />
                <meta property="og:title" content={`${product.name} - Cask International`} />
                <meta property="og:description" content={product.description || `${product.name} - ${product.volume} - Available at Cask International`} />
                <meta property="og:image" content={product.image} />
                <meta property="og:type" content="product" />
                <link rel="canonical" href={`https://cask.co.nz/product/${slug}/${id}`} />
            </Helmet>

            <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
                <div className="container mx-auto px-4 py-12 max-w-6xl">
                    {/* Back Button */}
                    <Link
                        to="/products"
                        className="inline-flex items-center gap-2 text-amber-300 hover:text-amber-400 transition-colors mb-8 animate-fadeIn"
                    >
                        <ChevronLeft size={20} />
                        <span>Back to Products</span>
                    </Link>

                    {/* Product Details */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 animate-fadeIn">
                        {/* Image Section */}
                        <div className="relative">
                            <div className="aspect-w-1 aspect-h-1">
                                <img
                                    src={product.image}
                                    alt={product.name}
                                    className="w-full h-full object-cover transition-opacity duration-500"
                                />
                            </div>
                            {product.stock <= 10 && (
                                <div className="absolute top-4 left-4 bg-red-500/90 px-3 py-1 animate-fadeIn">
                                    <span className="text-xs font-medium tracking-wider text-white uppercase">
                                        Low Stock
                                    </span>
                                </div>
                            )}
                        </div>

                        {/* Product Info */}
                        <div className="space-y-8">
                            <div>
                                <span className="text-amber-300/80 text-sm tracking-wider uppercase">
                                    {product.category}
                                </span>
                                <h1 className="text-3xl lg:text-4xl font-light text-white tracking-wider mt-2">
                                    {product.name}
                                </h1>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div className="p-4 bg-neutral-800/50 flex items-center gap-2">
                                    <Package size={24} className="text-amber-300" />
                                    <div>
                                        <span className="block text-sm text-gray-400">Volume</span>
                                        <span className="text-lg text-white">{product.volume}</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-neutral-800/50 flex items-center gap-2">
                                    <BarChart size={24} className="text-amber-300" />
                                    <div>
                                        <span className="block text-sm text-gray-400">ABV</span>
                                        <span className="text-lg text-white">{product.alcPercentage}%</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-neutral-800/50 flex items-center gap-2">
                                    <Check size={24} className="text-amber-300" />
                                    <div>
                                        <span className="block text-sm text-gray-400">SKU</span>
                                        <span className="text-lg text-white">{product.sku}</span>
                                    </div>
                                </div>
                                <div className="p-4 bg-neutral-800/50 flex items-center gap-2">
                                    <Info size={24} className="text-amber-300" />
                                    <div>
                                        <span className="block text-sm text-gray-400">Status</span>
                                        <span className="text-lg text-white">
                                            {product.stock > 10 ? 'In Stock' : 'Low Stock'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {product.description && (
                                <div className="prose prose-invert max-w-none">
                                    <p className="text-gray-400 leading-relaxed">
                                        {product.description}
                                    </p>
                                </div>
                            )}
                            <a href='/login'>
                            <button className="w-full md:w-auto flex items-center justify-center gap-2 py-4 px-8 bg-amber-300 text-neutral-900 hover:bg-amber-400 transition-colors group">
                                <LogIn size={24} className="transition-transform duration-300 group-hover:rotate-180" />
                                <span className="tracking-wider uppercase">Login to see price</span>
                            </button>
                            </a>
                        </div>
                    </div>

                    {/* Related Products */}
                    {relatedProducts.length > 0 && (
                        <div className="mt-24">
                            <h2 className="text-2xl lg:text-3xl font-light text-white tracking-wider mb-8 animate-fadeIn">
                                Related Products
                            </h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 animate-fadeIn">
                                {relatedProducts.map((related, index) => (
                                    <Link
                                        key={related.id}
                                        to={`/product/${generateSlug(related.name)}/${related.id}`}
                                        className="group block animate-slideUp"
                                        style={{ animationDelay: `${index * 100}ms` }}
                                    >
                                        <div className="relative mb-4">
                                            <div className="aspect-w-1 aspect-h-1">
                                                <img
                                                    src={related.image}
                                                    alt={related.name}
                                                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                                                />
                                            </div>
                                        </div>
                                        <h3 className="text-white font-light group-hover:text-amber-300 transition-colors">
                                            {related.name}
                                        </h3>
                                        <p className="text-sm text-gray-400">{related.volume}</p>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Animations */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes slideUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .animate-fadeIn {
                    animation: fadeIn 0.5s ease-out forwards;
                }

                .animate-slideUp {
                    animation: slideUp 0.5s ease-out forwards;
                }
            `}</style>
        </>
    );
};

export default ProductDetail;