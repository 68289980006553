import { jwtDecode } from 'jwt-decode';
import { API_URL } from './Settings.config';
import {useEffect, useState} from "react";

export const validateToken = async (token) => {
    if (!token) {
        return false;
    }

    try {
        // Decode token to check expiration
        const decodedToken = jwtDecode(token);

        const currentTime = Math.floor(Date.now() / 1000);
        console.log(decodedToken.exp , currentTime)
        if (decodedToken.exp < currentTime) {
            return false;
        }

        // Verify token with backend
        console.log("Multiple calls")
        const response = await fetch(`${API_URL}/auth/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            return false;
        }

        return true;
    } catch (error) {
        console.error('Token validation error:', error);
        return false;
    }
};

// Function to handle token refresh
export const refreshToken = async (refreshToken) => {
    try {
        const response = await fetch(`${API_URL}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refreshToken })
        });

        if (!response.ok) {
            throw new Error('Token refresh failed');
        }

        const data = await response.json();
        localStorage.setItem('t', data.token);
        localStorage.setItem('r', data.rtoken);

        return data.token;
    } catch (error) {
        console.error('Token refresh error:', error);
        localStorage.removeItem('t');
        localStorage.removeItem('r');
        window.location.href = '/login';
        return null;
    }
};

// Auth check HOC
export const withAuth = (WrappedComponent) => {
    return function WithAuthComponent(props) {
        const [isValid, setIsValid] = useState(false);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const checkAuth = async () => {
                const token = localStorage.getItem('t');
                const refreshTokenStr = localStorage.getItem('r');

                let isTokenValid = await validateToken(token);

                if (!isTokenValid && refreshTokenStr) {
                    const newToken = await refreshToken(refreshTokenStr);
                    isTokenValid = newToken ? await validateToken(newToken) : false;
                }

                setIsValid(isTokenValid);
                setLoading(false);

                if (!isTokenValid) {
                    window.location.href = '/login';
                }
            };

            checkAuth();
        }, []);

        if (loading) {
            return <div>Loading...</div>;
        }

        return isValid ? <WrappedComponent {...props} /> : null;
    };
};