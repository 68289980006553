import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, LogIn, Loader } from 'lucide-react';
import { Link } from 'react-router-dom';
import { generateSlug } from './ProductDetail';
import {API_URL} from "./Settings.config";

const ProductCard = ({ product }) => (
    <Link
        to={`/product/${generateSlug(product.name)}/${product.id}`}
        className="min-w-[280px] max-w-[280px] bg-neutral-800/50 backdrop-blur-sm border border-amber-300/10 group/card hover:border-amber-300/30 transition-all duration-500 snap-start"
    >
        <div className="relative">
            <div className="relative w-full h-[350px] overflow-hidden">
                <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-full object-contain bg-neutral-900"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-neutral-900 via-neutral-900/20 to-transparent pointer-events-none"></div>

                {product.stock <= 10 && (
                    <div className="absolute top-4 left-4 bg-red-500/90 px-3 py-1">
                        <span className="text-xs font-medium tracking-wider text-white uppercase">Low Stock</span>
                    </div>
                )}
            </div>

            <div className="absolute bottom-0 left-0 right-0 p-6">
                <span className="text-amber-300/80 text-sm tracking-wider uppercase">{product.category}</span>
                <h3 className="text-white text-lg font-light tracking-wider mt-2 line-clamp-2">{product.name}</h3>
                <p className="text-gray-400 text-sm tracking-wider mt-1">{product.volume}</p>
            </div>
        </div>

        <div className="p-6 border-t border-amber-300/10">
            <button className="w-full flex items-center justify-center gap-2 text-amber-300 hover:text-amber-400 transition-colors duration-300">
                <LogIn size={18} />
                <span className="text-sm tracking-wider uppercase">Login to see price</span>
            </button>
        </div>
    </Link>
);

const ProductScroller = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const host = API_URL;
    const url = host + "/invoice/top-products";

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error('Failed to fetch products');
                const data = await response.json();

                const formattedProducts = data
                    .filter(product =>
                        (product.extra_details?.website_image_url || product.image_url) !== undefined
                    )
                    .map(product => ({
                        id: product.id,
                        name: product.product_name,
                        category: product.product_category,
                        volume: `${product.quantity}ML`,
                        alcPercentage: product.alc_percentage,
                        stock: product.available_stock,
                        image: product.extra_details?.website_image_url
                            ? `${host}/images/${product.extra_details.website_image_url}`
                            : `${host}/images/${product.image_url}`
                    }));

                setProducts(formattedProducts);
            } catch (err) {
                console.error('Error fetching products:', err);
                setError('Failed to load promoted products');
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const scroll = (direction) => {
        const container = document.getElementById('product-container');
        const scrollAmount = direction === 'left' ? -300 : 300;
        container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    };

    if (loading) {
        return (
            <section className="py-24 bg-neutral-900">
                <div className="container mx-auto px-6 flex justify-center items-center">
                    <Loader size={32} className="text-amber-300 animate-spin" />
                </div>
            </section>
        );
    }

    if (error) {
        return (
            <section className="py-24 bg-neutral-900">
                <div className="container mx-auto px-6 text-center text-red-400">
                    {error}
                </div>
            </section>
        );
    }

    if (products.length === 0) {
        return (
            <section className="py-24 bg-neutral-900">
                <div className="container mx-auto px-6 text-center text-gray-400">
                    No promoted products available at the moment.
                </div>
            </section>
        );
    }

    return (
        <section className="py-24 bg-neutral-900 relative overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 opacity-5">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-amber-300/10 via-transparent to-transparent"></div>
            </div>

            <div className="container mx-auto px-6 relative">
                {/* Section Header */}
                <div className="text-center mb-16 max-w-2xl mx-auto">
                    <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6">
                        Featured Products
                    </h2>
                    <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                    <p className="text-gray-400 font-light tracking-wide">
                        Discover our specially curated selection of premium beverages.
                    </p>
                </div>

                {/* Product Scroller */}
                <div className="relative group">
                    {/* Scroll Buttons */}
                    <button
                        onClick={() => scroll('left')}
                        className="absolute -left-4 top-1/2 -translate-y-1/2 z-10 bg-neutral-900/80 text-amber-300 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:bg-neutral-800"
                    >
                        <ChevronLeft size={24} />
                    </button>

                    <button
                        onClick={() => scroll('right')}
                        className="absolute -right-4 top-1/2 -translate-y-1/2 z-10 bg-neutral-900/80 text-amber-300 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:bg-neutral-800"
                    >
                        <ChevronRight size={24} />
                    </button>

                    {/* Products Container */}
                    <div
                        id="product-container"
                        className="flex overflow-x-auto scrollbar-hide gap-6 pb-6 snap-x snap-mandatory"
                        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                    >
                        {products.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                    </div>
                </div>

                {/* View All Button */}
                <div className="text-center mt-12">
                    <Link
                        to="/products"
                        className="inline-block border border-amber-300 text-amber-300 px-8 py-3 uppercase tracking-wider text-sm hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300"
                    >
                        View All Products
                    </Link>
                </div>
            </div>

            <style jsx>{`
                .scrollbar-hide::-webkit-scrollbar {
                    display: none;
                }
                .scrollbar-hide {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
            `}</style>
        </section>
    );
};

export default ProductScroller;