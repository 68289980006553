import React from 'react';
import { Wine, Beer, GlassWater } from 'lucide-react';

const ProductCard = ({ icon: Icon, title, description }) => (
    <div className="group bg-neutral-900 p-12 text-center text-white relative overflow-hidden transform hover:-translate-y-2 transition-all duration-300">
      <div className="absolute inset-0 bg-gradient-to-b from-amber-300/0 to-amber-300/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
      <div className="relative z-10">
        <div className="flex justify-center mb-8">
          <Icon size={48} className="text-amber-300" />
        </div>
        <h3 className="text-xl font-light mb-4 tracking-wider uppercase">{title}</h3>
        <p className="text-gray-400 font-light tracking-wide">{description}</p>
      </div>
    </div>
  );
  
  // Products Section with modern grid
  const Products = () => {
    const products = [
      {
        icon: Wine,
        title: "Premium Wines",
        description: "Expertly curated selection of exceptional wines from renowned vineyards worldwide"
      },
      {
        icon: Beer,
        title: "Rare Whiskeys",
        description: "Distinguished collection of limited edition and aged whiskeys for the discerning palate"
      },
      {
        icon: GlassWater,
        title: "Artisanal Spirits",
        description: "Meticulously crafted premium vodkas and refined spirits for the ultimate experience"
      }
    ];
  
    return (
      <section id="products" className="py-24 bg-neutral-900">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-light text-center mb-16 tracking-wider uppercase text-white">Curated Collection</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {products.map((product, index) => (
              <ProductCard key={index} {...product} />
            ))}
          </div>
        </div>
      </section>
    );
  };
  

export default Products;