import React, { useState, useEffect } from 'react';
import { Menu, X, User, ChevronDown } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { validateToken } from './JWTTokenValidator';
import {ANGULAR_URL} from "./Settings.config";

const Header = ({ sectionRefs, scrollToSection }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAuthDropdownOpen, setIsAuthDropdownOpen] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const imageLocation = '/images/glogo.png';

    // Check authentication status on component mount and token changes
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('t');
            if (token) {
                const isValid = await validateToken(token);
                setIsAuthenticated(isValid);
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    const menuItems = [
        {
            title: 'Home',
            action: () => {
                navigate('/');
                setTimeout(() => scrollToSection(sectionRefs.home), 100);
            }
        },
        {
            title: 'Products',
            submenu: [
                {
                    title: 'All Products',
                    action: () => {
                        navigate('/products')
                        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
                    }
                },
                {
                    title: 'Best Sellers',
                    action: () => {
                        navigate('/');
                        setTimeout(() => scrollToSection(sectionRefs.bestSellers), 100);
                    }
                }
            ]
        },
        {
            title: 'About',
            submenu: [
                {
                    title: 'Our Story',
                    action: () => {
                        navigate('/');
                        setTimeout(()=>scrollToSection(sectionRefs.about),100);
                    }
                },
                {
                    title: 'Why Choose Us',
                    action: () => {
                        navigate('/');
                        setTimeout(()=>scrollToSection(sectionRefs.whyChooseUs),100);
                    }
                },
                {
                    title: 'Become a Partner',
                    action: () => {
                        navigate('/partner');

                    }
                },
                {
                    title: 'Services',
                    action: () => {
                        navigate('/');
                        setTimeout(()=>scrollToSection(sectionRefs.services),100);
                    }
                }
            ]
        },
        {
            title: 'Contact',
            action: () => {
                navigate('/contact')
                setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
            }
        }
    ];

    // Get authenticated user menus
    const getAuthMenuItems = () => {
        if (isAuthenticated) {
            return [
                {
                    title: 'Dashboard',
                    href: ANGULAR_URL + '/admin'
                },
                {
                    title: 'My Orders',
                    href: ANGULAR_URL +'/admin/order'
                },
                {
                    title: 'Settings',
                    href: ANGULAR_URL +'/admin/account'
                },
                {
                    title: 'Logout',
                    href: ANGULAR_URL+'/auth/auto-logout'
                }
            ];
        }
        return [
            {
                title: 'Login',
                href: '/login'
            },
            {
                title: 'Sign Up',
                href: '/signup'
            }
        ];
    };

    const handleMenuItemClick = (action) => {
        action();
        setIsMenuOpen(false);
        setActiveSubmenu(null);
    };

    const handleLogoClick = () => {
        navigate('/');
        setTimeout(() => scrollToSection(sectionRefs.home), 100);
    };

    const toggleSubmenu = (index) => {
        setActiveSubmenu(activeSubmenu === index ? null : index);
    };

    return (
        <header className="fixed w-full bg-gradient-to-r from-neutral-900 to-neutral-800 text-white z-40 backdrop-blur-sm bg-opacity-90">
            <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
                {/* Logo Section */}
                <div className="flex items-center gap-4 cursor-pointer" onClick={handleLogoClick}>
                    <div className="w-12 h-12 md:w-16 md:h-16 relative flex items-center">
                        <img
                            src={imageLocation}
                            alt="Cask International Logo"
                            className="object-contain"
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <span className="text-lg md:text-xl font-light tracking-wider text-amber-300">CASK</span>
                        <span className="text-xs tracking-wider text-gray-400">INTERNATIONAL</span>
                    </div>
                </div>

                {/* Desktop Navigation */}
                <div className="hidden md:flex items-center gap-8">
                    {menuItems.map((item, index) => (
                        <div key={index} className="relative group">
                            {item.submenu ? (
                                <button
                                    onClick={() => toggleSubmenu(index)}
                                    className="flex items-center gap-1 hover:text-amber-300 transition-colors duration-300 uppercase text-sm tracking-wider"
                                >
                                    {item.title}
                                    <ChevronDown size={16} className="group-hover:text-amber-300" />
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleMenuItemClick(item.action)}
                                    className="hover:text-amber-300 transition-colors duration-300 uppercase text-sm tracking-wider"
                                >
                                    {item.title}
                                </button>
                            )}

                            {/* Desktop Submenu */}
                            {item.submenu && activeSubmenu === index && (
                                <div className="absolute left-0 mt-2 w-56 bg-neutral-800 border border-amber-300/10 shadow-lg">
                                    {item.submenu.map((subItem, subIndex) => (
                                        <button
                                            key={subIndex}
                                            onClick={() => handleMenuItemClick(subItem.action)}
                                            className="block w-full text-left px-4 py-3 text-sm hover:bg-neutral-700 hover:text-amber-300 transition-colors duration-300 uppercase tracking-wider"
                                        >
                                            {subItem.title}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    {/* Auth Section */}
                    <div className="relative">
                        <button
                            onClick={() => setIsAuthDropdownOpen(!isAuthDropdownOpen)}
                            className="flex items-center gap-2 text-amber-300 hover:text-amber-400 transition-colors duration-300 ml-4"
                        >
                            <User size={20} />
                            <span className="uppercase text-sm tracking-wider">
                                {isAuthenticated ? 'Account' : 'Login'}
                            </span>
                        </button>

                        {/* Auth Dropdown */}
                        {isAuthDropdownOpen && (
                            <div className="absolute right-0 mt-4 w-48 bg-neutral-800 border border-amber-300/10 shadow-lg">
                                {getAuthMenuItems().map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item.href}
                                        className="block px-4 py-3 text-sm hover:bg-neutral-700 hover:text-amber-300 transition-colors duration-300 uppercase tracking-wider border-t first:border-t-0 border-amber-300/10"
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Mobile Menu Button */}
                <button
                    className="md:hidden text-amber-300"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    aria-label="Toggle menu"
                >
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </nav>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="md:hidden absolute w-full bg-neutral-900 border-t border-neutral-800">
                    <div className="container mx-auto px-6 py-4 flex flex-col gap-2">
                        {menuItems.map((item, index) => (
                            <div key={index} className="border-b border-neutral-800 last:border-b-0">
                                {item.submenu ? (
                                    <div>
                                        <button
                                            onClick={() => toggleSubmenu(index)}
                                            className="flex items-center justify-between w-full py-3 hover:text-amber-300 transition-colors duration-300 uppercase text-sm tracking-wider"
                                        >
                                            {item.title}
                                            <ChevronDown
                                                size={16}
                                                className={`transform transition-transform duration-300 ${
                                                    activeSubmenu === index ? 'rotate-180' : ''
                                                }`}
                                            />
                                        </button>
                                        {activeSubmenu === index && (
                                            <div className="pl-4 pb-3">
                                                {item.submenu.map((subItem, subIndex) => (
                                                    <button
                                                        key={subIndex}
                                                        onClick={() => handleMenuItemClick(subItem.action)}
                                                        className="block w-full text-left py-2 text-gray-400 hover:text-amber-300 transition-colors duration-300 uppercase text-sm tracking-wider"
                                                    >
                                                        {subItem.title}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <button
                                        onClick={() => handleMenuItemClick(item.action)}
                                        className="block w-full text-left py-3 hover:text-amber-300 transition-colors duration-300 uppercase text-sm tracking-wider"
                                    >
                                        {item.title}
                                    </button>
                                )}
                            </div>
                        ))}

                        {/* Mobile Auth Links */}
                        <div className="border-t border-neutral-700 pt-4 mt-2">
                            {getAuthMenuItems().map((item, index) => (
                                <Link
                                    key={index}
                                    to={item.href}
                                    className="block text-center text-amber-300 hover:text-amber-400 transition-colors duration-300 uppercase text-sm tracking-wider py-2"
                                >
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;