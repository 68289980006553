import React, { useState } from 'react';
import {
    Mail,
    Phone,
    MapPin,
    MessageSquare,
    Lock,
    Clock,
    CheckCircle2,
    Loader,
    ArrowRight,
    Shield,
    Users,
    Building
} from 'lucide-react';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        type: 'general'
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Simulate form submission
        await new Promise(resolve => setTimeout(resolve, 1500));
        setSuccess(true);
        setLoading(false);
    };

    const contactMethods = [
        {
            icon: Phone,
            title: "Call Us",
            description: "Speak directly with our team",
            primary: "02 777 999 77",
            secondary: "Monday - Friday, 9am - 5pm"
        },
        {
            icon: Mail,
            title: "Email Us",
            description: "Get in touch via email",
            primary: "info@cask.co.nz",
            secondary: "sales@cask.co.nz"
        },
        {
            icon: MapPin,
            title: "Visit Us",
            description: "Our office location",
            primary: "40 Casey Avenue, Fairfield",
            secondary: "Hamilton, Waikato 3214"
        }
    ];

    const inquiryTypes = [
        { value: 'general', label: 'General Inquiry' },
        { value: 'sales', label: 'Sales & Pricing' },
        { value: 'support', label: 'Customer Support' },
        { value: 'partnership', label: 'Partnership Opportunities' }
    ];

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
            {/* Hero Section */}
            <section className="relative overflow-hidden py-24">
                <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-gradient-to-r from-amber-300/5 to-transparent"></div>
                    <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-neutral-900"></div>
                </div>

                <div className="container mx-auto px-6 relative">
                    <div className="max-w-3xl">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white tracking-wider uppercase mb-6">
                            Let's Connect
                        </h1>
                        <p className="text-xl text-gray-400 tracking-wide leading-relaxed">
                            We're here to help. Reach out to us through any of our secure channels
                            and experience our commitment to exceptional service.
                        </p>
                    </div>
                </div>
            </section>

            {/* Trust Indicators */}
            <section className="border-y border-amber-300/10 bg-neutral-800/50">
                <div className="container mx-auto px-6 py-12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center">
                                <Shield className="text-amber-300" />
                            </div>
                            <div>
                                <h3 className="text-white font-light mb-1">Secure Communication</h3>
                                <p className="text-sm text-gray-400">Your information is protected</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center">
                                <Clock className="text-amber-300" />
                            </div>
                            <div>
                                <h3 className="text-white font-light mb-1">Quick Response</h3>
                                <p className="text-sm text-gray-400">Within 24 business hours</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center">
                                <Users className="text-amber-300" />
                            </div>
                            <div>
                                <h3 className="text-white font-light mb-1">Dedicated Support</h3>
                                <p className="text-sm text-gray-400">Personal account managers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Main Contact Section */}
            <section className="py-24">
                <div className="container mx-auto px-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
                        {/* Contact Methods */}
                        <div>
                            <h2 className="text-2xl font-light text-white tracking-wider uppercase mb-12">
                                Get in Touch
                            </h2>

                            <div className="space-y-8">
                                {contactMethods.map((method, index) => (
                                    <div
                                        key={index}
                                        className="group bg-neutral-800/50 p-6 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500"
                                    >
                                        <div className="flex gap-4">
                                            <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center group-hover:bg-amber-300/20 transition-colors">
                                                <method.icon className="text-amber-300" />
                                            </div>
                                            <div>
                                                <h3 className="text-white font-light mb-2">{method.title}</h3>
                                                <p className="text-sm text-gray-400 mb-3">{method.description}</p>
                                                <p className="text-amber-300 font-light mb-1">{method.primary}</p>
                                                <p className="text-sm text-gray-400">{method.secondary}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Privacy Notice */}
                            <div className="mt-12 p-6 bg-neutral-800/50 border border-amber-300/10">
                                <div className="flex gap-4">
                                    <Lock className="text-amber-300 flex-shrink-0" />
                                    <div>
                                        <h3 className="text-white font-light mb-2">Your Privacy Matters</h3>
                                        <p className="text-sm text-gray-400">
                                            We respect your privacy and handle all communications with strict confidentiality.
                                            Your information is encrypted and never shared with third parties.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Contact Form */}
                        <div className="lg:sticky lg:top-24">
                            <div className="bg-neutral-800/50 border border-amber-300/10 p-8">
                                <h2 className="text-2xl font-light text-white tracking-wider uppercase mb-8">
                                    Send Us a Message
                                </h2>

                                {success ? (
                                    <div className="text-center py-12">
                                        <div className="w-16 h-16 bg-amber-300/10 rounded-full flex items-center justify-center mx-auto mb-6">
                                            <CheckCircle2 className="text-amber-300" size={32} />
                                        </div>
                                        <h3 className="text-white font-light mb-2">Message Sent Successfully</h3>
                                        <p className="text-gray-400">We'll get back to you within 24 business hours.</p>
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-2">Name</label>
                                                <input
                                                    type="text"
                                                    required
                                                    className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white placeholder-gray-500 focus:outline-none focus:border-amber-300/30"
                                                    value={formData.name}
                                                    onChange={e => setFormData({...formData, name: e.target.value})}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-2">Email</label>
                                                <input
                                                    type="email"
                                                    required
                                                    className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white placeholder-gray-500 focus:outline-none focus:border-amber-300/30"
                                                    value={formData.email}
                                                    onChange={e => setFormData({...formData, email: e.target.value})}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-2">Phone</label>
                                                <input
                                                    type="tel"
                                                    className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white placeholder-gray-500 focus:outline-none focus:border-amber-300/30"
                                                    value={formData.phone}
                                                    onChange={e => setFormData({...formData, phone: e.target.value})}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-2">Company</label>
                                                <input
                                                    type="text"
                                                    className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white placeholder-gray-500 focus:outline-none focus:border-amber-300/30"
                                                    value={formData.company}
                                                    onChange={e => setFormData({...formData, company: e.target.value})}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm text-gray-400 mb-2">Inquiry Type</label>
                                            <select
                                                className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white focus:outline-none focus:border-amber-300/30"
                                                value={formData.type}
                                                onChange={e => setFormData({...formData, type: e.target.value})}
                                            >
                                                {inquiryTypes.map(type => (
                                                    <option key={type.value} value={type.value}>{type.label}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="block text-sm text-gray-400 mb-2">Message</label>
                                            <textarea
                                                required
                                                rows={4}
                                                className="w-full px-4 py-3 bg-neutral-900 border border-amber-300/10 text-white placeholder-gray-500 focus:outline-none focus:border-amber-300/30"
                                                value={formData.message}
                                                onChange={e => setFormData({...formData, message: e.target.value})}
                                            ></textarea>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-full bg-amber-300 text-neutral-900 py-4 flex items-center justify-center gap-2 hover:bg-amber-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed group"
                                        >
                                            {loading ? (
                                                <Loader className="animate-spin" />
                                            ) : (
                                                <>
                                                    <span className="tracking-wider uppercase text-sm">Send Message</span>
                                                    <ArrowRight className="transition-transform group-hover:translate-x-1" />
                                                </>
                                            )}
                                        </button>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Map Section */}
            <section className="py-24 bg-neutral-800/50 border-t border-amber-300/10">
                <div className="container mx-auto px-6">
                    <div className="text-center max-w-2xl mx-auto mb-16">
                        <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">
                            Visit Our Office
                        </h2>
                        <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                        <p className="text-gray-400 tracking-wide">
                            Our doors are always open for our partners and clients
                        </p>
                    </div>

                    <div className="aspect-[16/9] bg-neutral-900 relative overflow-hidden">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8!2d175.2527778!3d-37.7833333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzfCsDQ3JzAwLjAiUyAxNzXCsDE1JzEwLjAiRQ!5e0!3m2!1sen!2snz!4v1620898876543!5m2!1sen!2snz"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactPage;