import React from 'react';
import {
    ClipboardList,
    FileText,
    Receipt,
    Calendar,
    CreditCard,
    UserCog,
    Smartphone,
    Tablet,
    Users,
    MessageSquare
} from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, index }) => (
    <div
        className="group relative animate-fadeIn"
        style={{
            animationDelay: `${index * 150}ms`,
            opacity: 0,
            animation: 'fadeIn 0.5s ease forwards'
        }}
    >
        <div className="p-8 bg-neutral-900/80 backdrop-blur-sm border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500 h-full relative overflow-hidden group">
            {/* Animated background gradient */}
            <div className="absolute inset-0 bg-gradient-to-r from-amber-300/0 to-amber-300/5 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-full transition-all duration-1000"></div>

            {/* Icon with animation */}
            <div className="relative transform group-hover:-translate-y-1 transition-transform duration-300">
                <Icon size={24} className="text-amber-300 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
            </div>

            {/* Content */}
            <div className="relative z-10">
                <h3 className="text-lg font-light text-white tracking-wider uppercase mb-3 transform group-hover:translate-x-2 transition-transform duration-300">{title}</h3>
                <p className="text-gray-400 text-sm tracking-wide leading-relaxed transform group-hover:translate-x-2 transition-transform duration-500">{description}</p>
            </div>

            {/* Decorative corner */}
            <div className="absolute bottom-0 right-0 w-12 h-12 transform translate-x-6 translate-y-6 bg-amber-300/10 group-hover:bg-amber-300/20 transition-all duration-500"></div>
        </div>
    </div>
);

const OrderManagementSection = () => {
    const features = [
        {
            icon: ClipboardList,
            title: "Smart Order Placement",
            description: "Place orders effortlessly through our AI-powered system, with intelligent product suggestions and quick reorder capabilities."
        },
        {
            icon: FileText,
            title: "Order History",
            description: "Access comprehensive order history with detailed tracking and status updates for all your past purchases."
        },
        {
            icon: Receipt,
            title: "Digital Invoicing",
            description: "View and download professionally generated invoices instantly for all your transactions."
        },
        {
            icon: Calendar,
            title: "Monthly Statements",
            description: "Access detailed monthly statements with complete transaction history and spending analytics."
        },
        {
            icon: CreditCard,
            title: "Payment History",
            description: "Track all your payments, outstanding balances, and manage payment methods in one place."
        },
        {
            icon: UserCog,
            title: "Account Management",
            description: "Take full control of your account settings, preferences, and user permissions."
        }
    ];

    return (
        <section className="py-24 bg-neutral-900 relative overflow-hidden">
            {/* Enhanced Background Effects */}
            <div className="absolute inset-0 overflow-hidden">
                {/* Animated gradient orbs */}
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl animate-pulse"></div>
                <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl animate-pulse" style={{ animationDelay: '1s' }}></div>

                {/* Diagonal lines */}
                <div className="absolute inset-0 opacity-5">
                    <div className="absolute inset-0" style={{
                        backgroundImage: 'linear-gradient(45deg, transparent 0%, transparent 48%, rgba(245, 158, 11, 0.1) 49%, rgba(245, 158, 11, 0.1) 51%, transparent 52%, transparent 100%)',
                        backgroundSize: '30px 30px'
                    }}></div>
                </div>
            </div>

            <div className="container mx-auto px-6 relative">
                {/* Animated Section Header */}
                <div className="text-center mb-16 max-w-3xl mx-auto">
                    <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6 animate-fadeIn">
                        Smart Order Management System
                    </h2>
                    <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6 animate-scaleX"></div>
                    <p className="text-gray-400 tracking-wide mb-8 animate-fadeIn opacity-0" style={{ animationDelay: '0.3s' }}>
                        Experience seamless ordering with our AI-enabled Order Management System.
                        Access your account anytime, anywhere through our mobile and tablet applications.
                    </p>

                    {/* Enhanced Device Compatibility Section */}
                    <div className="flex justify-center gap-6 mb-12">
                        <div className="flex items-center gap-2 text-amber-300 animate-fadeIn opacity-0 hover:scale-110 transition-transform duration-300" style={{ animationDelay: '0.5s' }}>
                            <Smartphone size={20} className="animate-bounce" style={{ animationDuration: '2s' }} />
                            <span className="text-sm tracking-wider">Mobile App</span>
                        </div>
                        <div className="flex items-center gap-2 text-amber-300 animate-fadeIn opacity-0 hover:scale-110 transition-transform duration-300" style={{ animationDelay: '0.7s' }}>
                            <Tablet size={20} className="animate-bounce" style={{ animationDuration: '2s', animationDelay: '0.3s' }} />
                            <span className="text-sm tracking-wider">Tablet App</span>
                        </div>
                    </div>
                </div>

                {/* Enhanced Features Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} index={index} />
                    ))}
                </div>

                {/* Enhanced Support Info Box */}
                <div className="bg-neutral-800/50 border border-amber-300/10 p-8 md:p-12 rounded-none mb-12 transform hover:scale-[1.01] transition-transform duration-500 group">
                    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                        <div className="flex items-center gap-4 group/item">
                            <Users size={32} className="text-amber-300 transform group-hover/item:rotate-12 transition-transform duration-300" />
                            <div>
                                <h3 className="text-white text-lg font-light tracking-wider uppercase mb-2 group-hover/item:text-amber-300 transition-colors duration-300">Dedicated Support Team</h3>
                                <p className="text-gray-400 text-sm tracking-wide">Our representatives can visit your office for personal assistance and order collection</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-4 group/item">
                            <MessageSquare size={32} className="text-amber-300 transform group-hover/item:rotate-12 transition-transform duration-300" />
                            <div>
                                <h3 className="text-white text-lg font-light tracking-wider uppercase mb-2 group-hover/item:text-amber-300 transition-colors duration-300">24/7 Support</h3>
                                <p className="text-gray-400 text-sm tracking-wide">Get assistance anytime through our customer support channels</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Enhanced CTA */}
                <div className="text-center animate-fadeIn opacity-0" style={{ animationDelay: '1s' }}>
                    <a
                        href="/signup"
                        className="inline-block bg-amber-300 text-neutral-900 px-12 py-4 uppercase tracking-wider text-sm hover:bg-amber-400 transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg hover:shadow-amber-300/20"
                    >
                        Sign Up Now
                    </a>
                    <p className="text-gray-400 text-sm mt-4 tracking-wide">
                        Join our network of satisfied customers and experience premium service
                    </p>
                </div>
            </div>

            {/* Add required keyframe animations to the head */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                @keyframes scaleX {
                    from { transform: scaleX(0); }
                    to { transform: scaleX(1); }
                }
            `}</style>
        </section>
    );
};

export default OrderManagementSection;