const inozENV  =  "PRODUCTION"
let API_URL = "https://api.cask.co.nz"
let ANGULAR_URL = "https://app.cask.co.nz"
let WEBSITE_URL = "https://cask.co.nz"

if(inozENV === "DEVELOPMENT") {
    API_URL = "https://localhost"
    ANGULAR_URL = "http://localhost:4200"
    WEBSITE_URL = "http://localhost:3000"
}
export {API_URL, ANGULAR_URL, inozENV, WEBSITE_URL}