import React from 'react';
import {  Beer, GlassWater, Grape, WheatOff, Cherry } from 'lucide-react';

const BeverageCard = ({ icon: Icon, title, description }) => (
    <div className="group relative p-8 bg-neutral-900/50 backdrop-blur-sm border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
        <div className="absolute inset-0 bg-gradient-to-b from-amber-300/0 to-amber-300/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>

        <div className="relative z-10">
            <div className="flex justify-center mb-6">
                <Icon size={32} className="text-amber-300" />
            </div>

            <h3 className="text-xl font-light text-white mb-4 tracking-wider uppercase">
                {title}
            </h3>

            <p className="text-gray-400 font-light tracking-wide text-sm leading-relaxed">
                {description}
            </p>
        </div>
    </div>
);

const WhyChooseUs = () => {
    const beverages = [
        {
            icon: WheatOff,
            title: "Whiskey",
            description: "Discover our curated selection of whiskeys, expertly crafted from fermented grain mash, representing the finest distilleries worldwide."
        },
        {
            icon: GlassWater,
            title: "Rum",
            description: "Experience premium rums distilled from sugarcane molasses and juice, each bottle telling a story of traditional craftsmanship."
        },
        {
            icon: Cherry,
            title: "Brandy",
            description: "Indulge in our collection of fine brandies, distilled from selected wines, offering 35-60% alcohol content for the discerning palate."
        },
        {
            icon: Grape,
            title: "Wine",
            description: "Explore our extensive wine collection, featuring carefully selected fermented fruits from prestigious vineyards around the globe."
        },
        {
            icon: GlassWater,
            title: "Vodka",
            description: "Sample our premium vodka selection, featuring distinct varieties from Poland, Russia, and Sweden, each with its unique character."
        },
        {
            icon: Beer,
            title: "Beer",
            description: "Enjoy our craft beer selection, representing one of the world's oldest and most beloved beverages, carefully chosen for your pleasure."
        }
    ];

    return (
        <section className="py-24 bg-neutral-900 relative overflow-hidden">
            {/* Decorative Elements */}
            <div className="absolute inset-0">
                <div className="absolute top-0 left-0 w-64 h-64 bg-amber-300/5 rounded-full blur-3xl"></div>
                <div className="absolute bottom-0 right-0 w-64 h-64 bg-amber-300/5 rounded-full blur-3xl"></div>
            </div>

            <div className="container mx-auto px-6 relative">
                {/* Section Header */}
                <div className="text-center mb-16 max-w-2xl mx-auto">
                    <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6">
                        Why Cask International
                    </h2>
                    <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                    <p className="text-gray-400 font-light tracking-wide">
                        Discover our carefully curated selection of premium beverages, sourced from the finest producers worldwide.
                    </p>
                </div>

                {/* Beverages Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative z-10">
                    {beverages.map((beverage, index) => (
                        <BeverageCard
                            key={index}
                            {...beverage}
                        />
                    ))}
                </div>

                {/* CTA */}
                <div className="text-center mt-16">
                    <a
                        href="/products"
                        className="inline-block border border-amber-300 text-amber-300 px-8 py-3 uppercase tracking-wider text-sm hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300"
                    >
                        Explore Our Collection
                    </a>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;