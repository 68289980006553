/*
import React, { useState, useRef } from 'react';
import AgeVerification from './components/AgeVerification';
import Header from './components/Header';
import Hero from './components/Hero';
import Products from './components/Products';
import Footer from './components/Footer';
import WhyChooseUs from "./components/WhyChooseUs";
import AboutSection from "./components/AboutSection";
import ProductScroller from "./components/ProductScroller";
import ServicesSection from "./components/ServiceSection";
import BestSellers from "./components/BestSellers";
import OrderManagementSection from "./components/OrderManagementSection";

const App = () => {
    const [isVerified, setIsVerified] = useState(false);

    // Create refs for each section
    const homeRef = useRef(null);
    const bestSellersRef = useRef(null);
    const productsRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const whyChooseUsRef = useRef(null);
    const contactRef = useRef(null);

    // Scroll handler function
    const scrollToSection = (sectionRef) => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleVerification = (verified) => {
        if (verified) {
            setIsVerified(true);
        } else {
            window.location.href = 'https://www.google.com'
        }
    };

    // Create a map of section refs to pass to Header
    const sectionRefs = {
        home: homeRef,
        bestSellers: bestSellersRef,
        products: productsRef,
        about: aboutRef,
        services: servicesRef,
        whyChooseUs: whyChooseUsRef,
        contact: contactRef
    };

    return (
        <div className="bg-neutral-900">
            {!isVerified ? (
                <AgeVerification onVerify={handleVerification} />
            ) : (
                <>
                    <Header sectionRefs={sectionRefs} scrollToSection={scrollToSection}/>
                    <div ref={homeRef}>
                        <Hero/>
                    </div>
                    <div ref={bestSellersRef}>
                        <BestSellers/>
                    </div>
                    <ProductScroller/>
                    <div ref={servicesRef}>
                        <ServicesSection/>
                    </div>
                    <div ref={productsRef}>
                        <Products/>
                    </div>
                    <div ref={aboutRef}>
                        <AboutSection/>
                    </div>
                    <div ref={whyChooseUsRef}>
                        <WhyChooseUs/>
                    </div>
                    <div  >
                        <OrderManagementSection/>
                    </div>
                    <div ref={contactRef}>
                        <Footer/>
                    </div>
                </>
            )}
        </div>
    );
};

export default App;*/
// First, install react-router-dom:
// npm install react-router-dom

// App.js
import React, { useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AgeVerification from './components/AgeVerification';
import Header from './components/Header';
import Hero from './components/Hero';
import Products from './components/Products';
import Footer from './components/Footer';
import WhyChooseUs from "./components/WhyChooseUs";
import AboutSection from "./components/AboutSection";
import ProductScroller from "./components/ProductScroller";
import ServicesSection from "./components/ServiceSection";
import BestSellers from "./components/BestSellers";
import OrderManagementSection from "./components/OrderManagementSection";
import ProductListing from "./components/ProductListing";
import ProductDetail from './components/ProductDetail';
import { HelmetProvider } from 'react-helmet-async';
import {Login, Signup} from "./components/AuthComponents";
import PartnerWithUs from "./components/PartnerWithUs";
import ContactPage from "./components/ContactPage";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";

import LogoutPage from "./components/LogoutPage";

import  {   useEffect } from 'react';
import ForgotPassword from "./components/ForgotPassword";

const HomePage = ({ sectionRefs, scrollToSection }) => (
    <>
        <div ref={sectionRefs.home}>
            <Hero />
        </div>
        <div ref={sectionRefs.bestSellers}>
            <BestSellers />
        </div>
        <ProductScroller />
        <div ref={sectionRefs.services}>
            <ServicesSection />
        </div>
        <div ref={sectionRefs.products}>
            <Products />
        </div>
        <div ref={sectionRefs.about}>
            <AboutSection />
        </div>
        <div ref={sectionRefs.whyChooseUs}>
            <WhyChooseUs />
        </div>
        <div>
            <OrderManagementSection />
        </div>
    </>
);

const App = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [isCheckingVerification, setIsCheckingVerification] = useState(true);

    const sectionRefs = {
        home: useRef(null),
        bestSellers: useRef(null),
        products: useRef(null),
        about: useRef(null),
        services: useRef(null),
        whyChooseUs: useRef(null),
        contact: useRef(null)
    };

    const scrollToSection = (sectionRef) => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        // Check for existing verification
        const checkVerification = () => {
            const verificationStatus = localStorage.getItem('ageVerified');
            const verificationTimestamp = localStorage.getItem('ageVerifiedTimestamp');

            if (verificationStatus && verificationTimestamp) {
                const now = new Date().getTime();
                const timePassed = now - parseInt(verificationTimestamp);
                const hoursValid = 24;

                // Check if 24 hours have passed
                if (timePassed < hoursValid * 60 * 60 * 1000) {
                    setIsVerified(true);
                } else {
                    // Clear expired verification
                    localStorage.removeItem('ageVerified');
                    localStorage.removeItem('ageVerifiedTimestamp');
                    setIsVerified(false);
                }
            }
            setIsCheckingVerification(false);
        };

        checkVerification();
    }, []);

    const handleVerification = (verified) => {
        if (verified) {
            // Store verification status and timestamp
            localStorage.setItem('ageVerified', 'true');
            localStorage.setItem('ageVerifiedTimestamp', new Date().getTime().toString());
            setIsVerified(true);
        } else {
            window.location.href = 'https://www.google.com';
        }
    };

    if (isCheckingVerification) {
        // Optional: Show loading state while checking verification
        return <div className="min-h-screen bg-neutral-900"></div>;
    }

    if (!isVerified) {
        return <AgeVerification onVerify={handleVerification} />;
    }

    return (
        <HelmetProvider>
            <BrowserRouter>
                <div className="bg-neutral-900">
                    <Header sectionRefs={sectionRefs} scrollToSection={scrollToSection} />
                    <Routes>
                        <Route path="/" element={
                            <HomePage sectionRefs={sectionRefs} scrollToSection={scrollToSection} />
                        } />
                        <Route path="/products" element={<ProductListing />} />
                        <Route path="/product/:slug/:id" element={<ProductDetail />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/partner" element={<PartnerWithUs />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/terms" element={<TermsAndConditions />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                    </Routes>
                    <div ref={sectionRefs.contact}>
                        <Footer />
                    </div>
                </div>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App;