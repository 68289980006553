import React from 'react';
import { Link } from 'react-router-dom';
import {
    MapPin,
    Phone,
    Mail,
    Facebook,
    Instagram,
    ArrowRight
} from 'lucide-react';

const FooterHeading = ({ children }) => (
    <h3 className="text-white text-lg font-light tracking-wider uppercase mb-6 text-center md:text-left">
        {children}
    </h3>
);

const FooterLink = ({ to, children }) => (
    <Link
        to={to}
        className="block text-gray-400 hover:text-amber-300 transition-colors duration-300 py-2 text-sm tracking-wide text-center md:text-left"
    >
        {children}
    </Link>
);

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const quickLinks = [
        { title: "All Products", path: "/products" },
        { title: "Partner With Us", path: "/partner" },
        { title: "Login", path: "/login" },
        { title: "Sign Up", path: "/signup" }
    ];

    const companyLinks = [
        { title: "About Us", path: "/" },
        { title: "Contact Us", path: "/contact" },
        { title: "Terms & Conditions", path: "/terms" },
        { title: "Privacy Policy", path: "/privacy" }
    ];

    return (
        <footer className="bg-neutral-900 border-t border-neutral-800">
            {/* Main Footer Content */}
            <div className="container mx-auto px-6 py-16">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                    {/* Company Logo & Info */}
                    <div className="flex flex-col items-center md:items-start">
                        <div className="flex items-center gap-4 mb-6">
                            <img
                                src="/images/glogo.png"
                                alt="Cask International"
                                className="w-12 h-12 object-contain"
                            />
                            <div>
                                <h2 className="text-xl font-light text-amber-300 tracking-wider">CASK</h2>
                                <span className="text-sm tracking-wider text-gray-400">INTERNATIONAL</span>
                            </div>
                        </div>
                        <div className="flex gap-4 mb-6">
                            <a
                                href="#"
                                className="w-10 h-10 bg-neutral-800 hover:bg-amber-300 text-white hover:text-neutral-900 flex items-center justify-center transition-colors duration-300 transform hover:scale-110"
                            >
                                <Facebook size={18} />
                            </a>
                            <a
                                href="#"
                                className="w-10 h-10 bg-neutral-800 hover:bg-amber-300 text-white hover:text-neutral-900 flex items-center justify-center transition-colors duration-300 transform hover:scale-110"
                            >
                                <Instagram size={18} />
                            </a>
                        </div>
                        <Link
                            to="/partner"
                            className="inline-flex items-center gap-2 text-amber-300 hover:text-amber-400 transition-colors duration-300 group bg-neutral-800/50 px-6 py-2 hover:bg-neutral-800"
                        >
                            <span className="text-sm tracking-wider">Become a Partner</span>
                            <ArrowRight size={16} className="transition-transform group-hover:translate-x-1" />
                        </Link>
                    </div>

                    {/* Quick Links */}
                    <div className="flex flex-col items-center md:items-start">
                        <FooterHeading>Quick Links</FooterHeading>
                        <div className="space-y-2 w-full">
                            {quickLinks.map((link, index) => (
                                <FooterLink key={index} to={link.path}>
                                    {link.title}
                                </FooterLink>
                            ))}
                        </div>
                    </div>

                    {/* Company */}
                    <div className="flex flex-col items-center md:items-start">
                        <FooterHeading>Company</FooterHeading>
                        <div className="space-y-2 w-full">
                            {companyLinks.map((link, index) => (
                                <FooterLink key={index} to={link.path}>
                                    {link.title}
                                </FooterLink>
                            ))}
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className="flex flex-col items-center md:items-start">
                        <FooterHeading>Contact Us</FooterHeading>
                        <div className="space-y-6 w-full">
                            <div className="flex flex-col items-center md:items-start gap-3">
                                <div className="flex items-center justify-center md:justify-start gap-3 text-gray-400 w-full">
                                    <MapPin size={20} className="text-amber-300 flex-shrink-0" />
                                    <p className="text-sm text-center md:text-left">
                                        40 Casey Avenue Fairfield,<br className="hidden md:block" /> Hamilton Waikato 3214
                                    </p>
                                </div>
                                <div className="flex items-center justify-center md:justify-start gap-3 text-gray-400 w-full">
                                    <Phone size={20} className="text-amber-300 flex-shrink-0" />
                                    <p className="text-sm">02 777 999 77</p>
                                </div>
                                <div className="flex items-center justify-center md:justify-start gap-3 text-gray-400 w-full">
                                    <Mail size={20} className="text-amber-300 flex-shrink-0" />
                                    <p className="text-sm">info@cask.co.nz</p>
                                </div>
                            </div>

                            {/* Mobile Quick Contact */}
                            <div className="flex flex-col gap-3 md:hidden w-full">
                                <a
                                    href="tel:0277799977"
                                    className="w-full bg-amber-300 text-neutral-900 py-2 text-center text-sm tracking-wider uppercase hover:bg-amber-400 transition-colors"
                                >
                                    Call Now
                                </a>
                                <a
                                    href="mailto:info@cask.co.nz"
                                    className="w-full border border-amber-300 text-amber-300 py-2 text-center text-sm tracking-wider uppercase hover:bg-amber-300 hover:text-neutral-900 transition-colors"
                                >
                                    Email Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Copyright Bar */}
            <div className="border-t border-neutral-800">
                <div className="container mx-auto px-6 py-6">
                    <div className="flex flex-col items-center gap-4">
                        <p className="text-gray-400 text-sm tracking-wide text-center">
                            © {currentYear} Cask International. All rights reserved.
                        </p>
                        <div className="flex flex-wrap justify-center gap-8">
                            <Link to="/terms" className="text-gray-400 hover:text-amber-300 text-sm tracking-wide transition-colors duration-300">
                                Terms of Service
                            </Link>
                            <Link to="/privacy" className="text-gray-400 hover:text-amber-300 text-sm tracking-wide transition-colors duration-300">
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;