import React, { useState } from 'react';
import { Mail, ArrowRight, Loader, AlertCircle, CheckCircle2, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from './Settings.config';

// Reusing Alert component from AuthComponents
const Alert = ({ type, message }) => (
    <div className={`fixed top-16 md:top-24 right-4 left-4 md:left-auto md:w-96 z-50 ${
        type === 'error' ? 'bg-red-500/90 border-red-600' : 'bg-green-500/90 border-green-600'
    } text-white p-4 rounded-none border animate-fadeIn`}>
        <div className="flex items-center gap-2">
            {type === 'error' && <AlertCircle className="h-5 w-5 flex-shrink-0" />}
            {type === 'success' && <CheckCircle2 className="h-5 w-5 flex-shrink-0" />}
            <div className="flex-1">{message}</div>
        </div>
    </div>
);

// Reusing FormInput component from AuthComponents
const FormInput = ({ icon: Icon, type, placeholder, value, onChange, error }) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className="space-y-1">
            <div className={`relative group transition-all duration-300 ${error ? 'shake' : ''}`}>
                <div className={`absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 transition-colors duration-300 ${isFocused ? 'text-amber-300' : ''}`}>
                    <Icon size={20} />
                </div>
                <input
                    type={type}
                    className={`w-full pl-12 pr-4 py-3 bg-neutral-800 border ${
                        error ? 'border-red-500' : isFocused ? 'border-amber-300' : 'border-amber-300/10'
                    } text-white placeholder-gray-400 focus:outline-none transition-colors duration-300`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </div>
            {error && <p className="text-red-500 text-sm pl-1">{error}</p>}
        </div>
    );
};

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Basic validation
        if (!formData.email) {
            setErrors({ email: 'Email is required' });
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: formData.email })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to process request');
            }

            setAlert({
                type: 'success',
                message: 'Password reset instructions have been sent to your email'
            });

            // Redirect to login after showing success message
            setTimeout(() => {
                navigate('/login');
            }, 3000);

        } catch (error) {
            setAlert({
                type: 'error',
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24 px-4">
            {alert && <Alert {...alert} />}

            <div className="container mx-auto max-w-md">
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-light text-white tracking-wider uppercase mb-4 mt-12">
                        Reset Password
                    </h1>
                    <p className="text-gray-400 tracking-wide">
                        Enter your email address and we'll send you instructions to reset your password
                    </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <FormInput
                        icon={Mail}
                        type="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        error={errors.email}
                    />

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-amber-300 text-neutral-900 py-3 flex items-center justify-center gap-2 hover:bg-amber-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed group"
                    >
                        {loading ? (
                            <Loader className="animate-spin" size={20} />
                        ) : (
                            <>
                                <span className="tracking-wider uppercase">Reset Password</span>
                                <ArrowRight
                                    className="transition-transform duration-300 group-hover:translate-x-1"
                                    size={20}
                                />
                            </>
                        )}
                    </button>
                </form>

                <div className="mt-8 text-center">
                    <button
                        onClick={() => navigate('/login')}
                        className="text-amber-300 hover:text-amber-400 transition-colors inline-flex items-center gap-2"
                    >
                        <ChevronLeft size={20} />
                        <span>Back to Login</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;