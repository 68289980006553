import React, { useState, useEffect } from 'react';
import { ChevronDown, Volume2, VolumeX } from 'lucide-react';

const Hero = () => {
    const [isMuted, setIsMuted] = useState(true);
    const [activeBackground, setActiveBackground] = useState(0);
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);

    // Background content array - can be expanded with more options
    const backgrounds = [
        {
            type: 'video',
            src: '/video-1.mp4', // Replace with actual video URL
            poster: '/api/placeholder/1920/1080'
        },
        {
            type: 'image',
            src: '/image-1.jpg'
        }
    ];

    // Auto-rotate backgrounds
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveBackground((prev) => (prev + 1) % backgrounds.length);
        }, 8000); // Change every 8 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <section id="home" className="min-h-screen relative flex items-center justify-center text-white overflow-hidden">
            {/* Background Layer */}
            <div className="absolute inset-0 z-0">
                {backgrounds.map((background, index) => (
                    <div
                        key={index}
                        className={`absolute inset-0 transition-opacity duration-1000 ${
                            activeBackground === index ? 'opacity-100' : 'opacity-0'
                        }`}
                    >
                        {background.type === 'video' ? (
                            <video
                                autoPlay
                                loop
                                muted={isMuted}
                                playsInline
                                poster={background.poster}
                                className="absolute inset-0 w-full h-full object-cover scale-105 transform transition-transform duration-[2s]"
                                onPlay={() => setIsVideoPlaying(true)}
                            >
                                <source src={background.src} type="video/mp4" />
                            </video>
                        ) : (
                            <img
                                src={background.src}
                                alt="Hero background"
                                className="absolute inset-0 w-full h-full object-cover scale-105 transform hover:scale-100 transition-transform duration-[2s]"
                            />
                        )}
                    </div>
                ))}
            </div>

            {/* Overlay Gradients */}
            <div className="absolute inset-0 bg-black opacity-40 z-10"></div>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-neutral-900/50 to-neutral-900 z-10"></div>

            {/* Animated Grain Effect */}
            <div
                className="absolute inset-0 opacity-20 z-20"
                style={{
                    backgroundImage: 'url("data:image/svg+xml,%3Csvg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch"/%3E%3C/filter%3E%3Crect width="100%" height="100%" filter="url(%23noiseFilter)"/%3E%3C/svg%3E")',
                    backgroundRepeat: 'repeat',
                    mixBlendMode: 'multiply',
                }}
            ></div>

            {/* Content */}
            <div className="relative z-20 text-center max-w-4xl px-6">
                <h1 className="text-6xl md:text-7xl font-light mb-8 tracking-wider transform transition-transform duration-700 animate-fadeIn">
                    REFINED SPIRITS
                </h1>
                <p className="text-lg md:text-xl mb-12 tracking-wide text-gray-200 font-light transform transition-transform duration-700 delay-200 animate-fadeIn">
                    Experience the finest collection of prestigious wines, rare whiskeys, and artisanal spirits.
                    A legacy of excellence since 2018.
                </p>
                <a
                    href="/products"
                    className="border border-amber-300 text-amber-300 px-12 py-4 rounded-none hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300 uppercase tracking-wider text-sm inline-block transform hover:-translate-y-1 animate-fadeIn delay-300"
                >
                    Explore Collection
                </a>

                {/* Scroll Indicator */}
                <div className="absolute bottom-12 left-1/2 -translate-x-1/2 animate-bounce">
                    <ChevronDown size={24} className="text-amber-300" />
                </div>
            </div>

            {/* Video Controls */}
            {backgrounds[activeBackground].type === 'video' && isVideoPlaying && (
                <button
                    onClick={() => setIsMuted(!isMuted)}
                    className="absolute bottom-8 right-8 z-30 p-3 bg-neutral-900/50 backdrop-blur-sm hover:bg-neutral-900/80 transition-colors duration-300"
                >
                    {isMuted ? (
                        <VolumeX size={20} className="text-amber-300" />
                    ) : (
                        <Volume2 size={20} className="text-amber-300" />
                    )}
                </button>
            )}

            {/* Background Indicators */}
            <div className="absolute bottom-8 left-1/2 -translate-x-1/2 z-30 flex gap-2">
                {backgrounds.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveBackground(index)}
                        className={`w-2 h-2 rounded-full transition-all duration-300 ${
                            activeBackground === index
                                ? 'bg-amber-300 w-8'
                                : 'bg-white/50 hover:bg-white/80'
                        }`}
                    />
                ))}
            </div>

            <style jsx global>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          opacity: 0;
          animation: fadeIn 1s ease forwards;
        }
      `}</style>
        </section>
    );
};

export default Hero;