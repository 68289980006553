import React, { useState } from 'react';
import {
    ShieldCheck,
    TrendingUp,
    Truck,
    Users,
    Clock,
    BarChart3,
    CheckCircle2,
    ArrowRight,
    Sparkles,
    Building2,
    Globe,
    Award
} from 'lucide-react';
import { motion } from 'framer-motion';
// Inside PartnerWithUs.js
const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};
const PartnerWithUs = () => {
    const [activeTestimonial, setActiveTestimonial] = useState(0);

    const benefits = [
        {
            icon: ShieldCheck,
            title: "Premium Quality Assurance",
            description: "Access to certified, authentic products sourced directly from renowned distilleries worldwide."
        },
        {
            icon: TrendingUp,
            title: "Growth Partnership",
            description: "Benefit from our market insights and dedicated support to grow your business strategically."
        },
        {
            icon: Truck,
            title: "Reliable Distribution",
            description: "Nationwide cold chain logistics network ensuring your products arrive in perfect condition."
        },
        {
            icon: Clock,
            title: "Smart Ordering System",
            description: "24/7 access to our state-of-the-art digital ordering platform for seamless procurement."
        },
        {
            icon: Users,
            title: "Dedicated Support",
            description: "Personal account manager and expert team committed to your success."
        },
        {
            icon: BarChart3,
            title: "Market Intelligence",
            description: "Regular market insights and trend analysis to help optimize your inventory."
        }
    ];

    const testimonials = [
        {
            quote: "Partnering with Cask International has transformed our business. Their reliable service and premium products have helped us grow by 40% year over year.",
            author: "James Wilson",
            position: "Owner, The Wine Cellar",
            image: "/api/placeholder/80/80"
        },
        {
            quote: "The level of professionalism and support we receive is outstanding. Their smart ordering system has revolutionized our inventory management.",
            author: "Sarah Chen",
            position: "Operations Manager, Luxury Spirits Co",
            image: "/api/placeholder/80/80"
        },
        {
            quote: "From day one, their commitment to quality and service excellence has been impressive. A truly reliable partner in the beverage industry.",
            author: "Michael O'Connor",
            position: "Director, Highland Spirits",
            image: "/api/placeholder/80/80"
        }
    ];

    const stats = [
        { value: "5+", label: "Years Experience" },
        { value: "500+", label: "Active Partners" },
        { value: "98%", label: "Partner Retention" },
        { value: "24/7", label: "Support Available" }
    ];

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
            {/* Hero Section */}
            <section className="relative overflow-hidden">
                {/*<div className="absolute inset-0 bg-gradient-to-r from-amber-300/5 to-transparent"></div>*/}
                <div className="container mx-auto px-6 py-24">
                    <div className="max-w-3xl">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white tracking-wider uppercase mb-8">
                            Partner with Excellence
                        </h1>
                        <p className="text-xl text-gray-400 tracking-wide mb-12 leading-relaxed">
                            Join New Zealand's premier beverage distribution network. Experience growth, reliability, and excellence in every interaction.
                        </p>
                        <div className="flex flex-wrap gap-4">
                            <a
                                href="#contact-form"
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection('contact-form');
                                }}
                                className="inline-flex items-center gap-2 bg-amber-300 text-neutral-900 px-8 py-4 hover:bg-amber-400 transition-colors group"
                            >
                                <span className="tracking-wider uppercase text-sm">Become a Partner</span>
                                <ArrowRight className="transition-transform group-hover:translate-x-1"/>
                            </a>
                            <a href="#benefits"
                               className="inline-flex items-center gap-2 border border-amber-300 text-amber-300 px-8 py-4 hover:bg-amber-300 hover:text-neutral-900 transition-colors">
                                <span className="tracking-wider uppercase text-sm">Learn More</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Statistics Section */}
            <section className="border-y border-amber-300/10 bg-neutral-800/50">
                <div className="container mx-auto px-6 py-16">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        {stats.map((stat, index) => (
                            <div key={index} className="text-center">
                                <div className="text-4xl md:text-5xl font-light text-amber-300 mb-2">{stat.value}</div>
                                <div className="text-gray-400 tracking-wider uppercase text-sm">{stat.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Benefits Section */}
            <section id="benefits" className="py-24">
                <div className="container mx-auto px-6">
                    <div className="text-center max-w-2xl mx-auto mb-16">
                        <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">
                            Why Partner With Us
                        </h2>
                        <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                        <p className="text-gray-400 tracking-wide">
                            Discover the advantages of joining New Zealand's leading beverage distribution network
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {benefits.map((benefit, index) => (
                            <div key={index} className="group bg-neutral-800/50 p-8 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
                                <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center mb-6 group-hover:bg-amber-300/20 transition-colors">
                                    <benefit.icon className="text-amber-300" size={24} />
                                </div>
                                <h3 className="text-xl font-light text-white tracking-wider mb-4">{benefit.title}</h3>
                                <p className="text-gray-400 tracking-wide">{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="py-24 bg-neutral-800/50 border-y border-amber-300/10">
                <div className="container mx-auto px-6">
                    <div className="text-center max-w-2xl mx-auto mb-16">
                        <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">
                            Partner Success Stories
                        </h2>
                        <div className="w-24 h-px bg-amber-300/30 mx-auto"></div>
                    </div>

                    <div className="max-w-4xl mx-auto relative">
                        <div className="overflow-hidden">
                            <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${activeTestimonial * 100}%)` }}>
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className="w-full flex-shrink-0 px-6">
                                        <blockquote className="text-center">
                                            <p className="text-xl text-gray-300 italic mb-8">"{testimonial.quote}"</p>
                                            <div className="flex items-center justify-center gap-4">
                                                <img src={testimonial.image} alt={testimonial.author} className="w-16 h-16 rounded-full" />
                                                <div className="text-left">
                                                    <div className="text-white font-light">{testimonial.author}</div>
                                                    <div className="text-amber-300 text-sm">{testimonial.position}</div>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex justify-center gap-2 mt-8">
                            {testimonials.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveTestimonial(index)}
                                    className={`w-3 h-3 rounded-full transition-all duration-300 ${
                                        activeTestimonial === index ? 'bg-amber-300 w-6' : 'bg-gray-600 hover:bg-gray-500'
                                    }`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Form Section */}
            <section id="contact-form" className="py-24">
                <div className="container mx-auto px-6">
                    <div className="max-w-4xl mx-auto">
                        <div className="text-center mb-16">
                            <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">
                                Start Your Journey
                            </h2>
                            <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                            <p className="text-gray-400 tracking-wide">
                                Fill out the form below and our team will get in touch with you shortly
                            </p>
                        </div>

                        <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-1">
                                <input
                                    type="text"
                                    placeholder="Business Name"
                                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                                />
                            </div>
                            <div className="space-y-1">
                                <input
                                    type="text"
                                    placeholder="Contact Person"
                                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                                />
                            </div>
                            <div className="space-y-1">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                                />
                            </div>
                            <div className="space-y-1">
                                <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                                />
                            </div>
                            <div className="md:col-span-2">
                                <textarea
                                    placeholder="Tell us about your business"
                                    rows={4}
                                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                                ></textarea>
                            </div>
                            <div className="md:col-span-2">
                                <button type="submit" className="w-full bg-amber-300 text-neutral-900 py-4 hover:bg-amber-400 transition-colors uppercase tracking-wider text-sm">
                                    Submit Application
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PartnerWithUs;