import React from 'react';

const AgeVerification = ({ onVerify }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center px-4">
        <div className="bg-neutral-900 p-12 max-w-md w-full mx-4 border border-amber-300/30">
          <h2 className="text-2xl font-light mb-6 tracking-wider text-white text-center uppercase">Age Verification</h2>
          <p className="text-center mb-4 text-gray-300 tracking-wide">You must be 18 or older to visit this site.</p>
          <p className="text-center mb-8 text-gray-300 tracking-wide">Please confirm your age:</p>
          <div className="flex flex-col gap-4">
            <button
              onClick={() => onVerify(true)}
              className="w-full bg-amber-300 hover:bg-amber-400 text-neutral-900 px-6 py-3 uppercase tracking-wider text-sm transition-colors duration-300"
            >
              Yes, I am 18+
            </button>
            <button
              onClick={() => onVerify(false)}
              className="w-full border border-gray-600 hover:border-gray-500 text-gray-400 hover:text-gray-300 px-6 py-3 uppercase tracking-wider text-sm transition-colors duration-300"
            >
              No, I'm under 18
            </button>
          </div>
        </div>
      </div>
    );
  };

export default AgeVerification;