import React from 'react';
import { GlassWater, Truck, Globe2, Award } from 'lucide-react';

const AboutFeature = ({ icon: Icon, title, description }) => (
    <div className="flex flex-col items-center text-center p-6 bg-neutral-900/30 backdrop-blur-sm group hover:bg-neutral-900/50 transition-all duration-500">
        <Icon size={28} className="text-amber-300 mb-4" />
        <h3 className="text-white text-lg font-light tracking-wider uppercase mb-3">{title}</h3>
        <p className="text-gray-400 text-sm tracking-wide leading-relaxed">{description}</p>
    </div>
);

const AboutSection = () => {
    const features = [
        {
            icon: Globe2,
            title: "Global Sourcing",
            description: "Partnerships with top producers worldwide to bring exceptional beverages to New Zealand"
        },
        {
            icon: Award,
            title: "Premium Quality",
            description: "Carefully selected premium wines, spirits, and beers from renowned producers"
        },
        {
            icon: Truck,
            title: "Nationwide Delivery",
            description: "Efficient distribution network covering all of New Zealand"
        },
        {
            icon: GlassWater,
            title: "Expert Selection",
            description: "Years of industry experience ensuring only the finest products"
        }
    ];

    return (
        <section className="relative py-24 overflow-hidden">
            <div className="absolute inset-0 bg-neutral-900"></div>

            {/* Decorative Elements */}
            <div className="absolute inset-0 opacity-5">
                <div className="absolute right-0 top-0 w-1/2 h-1/2 bg-amber-300/10 blur-3xl"></div>
                <div className="absolute left-0 bottom-0 w-1/2 h-1/2 bg-amber-300/10 blur-3xl"></div>
            </div>

            <div className="container mx-auto px-6 relative">
                {/* Main Content Grid */}
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                    {/* Left Column - Image */}
                    <div className="relative">
                        <div className="aspect-[4/3] relative">
                            <img
                                src="/images/choose-us-2.jpeg"
                                alt="Premium spirits collection"
                                className="object-cover w-full h-full"
                            />
                            <div className="absolute inset-0 bg-gradient-to-r from-neutral-900/50 to-transparent"></div>
                        </div>

                        {/* Floating Stats Card - Responsive Design */}
                        <div className="absolute -bottom-8 -right-8 bg-neutral-900 shadow-xl border border-amber-300/10 transform transition-transform hover:scale-105">
                            {/* Desktop Version */}
                            <div className="hidden md:block p-8">
                                <div className="text-center">
                                    <span className="block text-3xl font-light text-amber-300 mb-2">Inimitable Taste</span>
                                    <span className="text-gray-400 tracking-wider text-sm">EST. 2018</span>
                                </div>
                            </div>
                            {/* Mobile Version - More Compact */}
                            <div className="block md:hidden p-4">
                                <div className="text-center">
                                    <span className="block text-xl font-light text-amber-300 mb-1">Premium Spirits</span>
                                    <span className="text-gray-400 tracking-wider text-xs">Since 2018</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Text Content */}
                    <div className="lg:pl-12">
                        <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6">
                            Welcome to Cask International
                        </h2>

                        <div className="w-24 h-px bg-amber-300/30 mb-6"></div>

                        <p className="text-gray-400 tracking-wide leading-relaxed mb-8">
                            Welcome to Cask International, New Zealand's premier import and distribution company for fine beverages. We specialize in sourcing high-quality alcoholic beverages from around the globe, delivering exceptional products across New Zealand.
                        </p>

                        <p className="text-gray-400 tracking-wide leading-relaxed mb-12">
                            Since 2018, we've cultivated strong relationships with top producers worldwide, ensuring an unparalleled selection of premium wines, spirits, and beers. Our commitment to excellence drives us to provide exceptional service, timely delivery, and competitive pricing.
                        </p>

                        <a
                            href="/contact"
                            className="inline-flex items-center justify-center border border-amber-300 text-amber-300 px-8 py-3 uppercase tracking-wider text-sm hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300 group"
                        >
                            Get In Touch
                        </a>
                    </div>
                </div>

                {/* Features Grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-24">
                    {features.map((feature, index) => (
                        <AboutFeature key={index} {...feature} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutSection;