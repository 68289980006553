import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut, Loader } from 'lucide-react';

const LogoutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            // Clear local storage
            localStorage.removeItem('t');
            localStorage.removeItem('r');
            console.log("CLEARED")
            // Redirect to login page after 2 seconds
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        };

        handleLogout();
    }, [navigate]);

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24 px-4">
            <div className="container mx-auto max-w-md">
                <div className="text-center mb-12">
                    <div className="w-16 h-16 bg-amber-300/10 rounded-full flex items-center justify-center mx-auto mb-8">
                        <LogOut size={32} className="text-amber-300" />
                    </div>
                    <h1 className="text-3xl font-light text-white tracking-wider uppercase mb-4">
                        Logging Out
                    </h1>
                    <div className="flex items-center justify-center gap-3 text-gray-400">
                        <Loader className="animate-spin" size={20} />
                        <p className="tracking-wide">Please wait while we sign you out...</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutPage;