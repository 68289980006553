import React from 'react';
import { Shield, AlertTriangle, Scale } from 'lucide-react';

import { AlertCircle, Book } from 'lucide-react';

const TermsHero = () => {
    return (
        <section className="relative min-h-[50vh] flex items-center justify-center overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0">
                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-b from-neutral-900 via-neutral-900/95 to-neutral-900"></div>

                {/* Decorative Elements */}
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>
                <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>

                {/* Diagonal Pattern */}
                <div className="absolute inset-0 opacity-5">
                    <div className="absolute inset-0" style={{
                        backgroundImage: 'linear-gradient(45deg, transparent 0%, transparent 48%, rgba(245, 158, 11, 0.1) 49%, rgba(245, 158, 11, 0.1) 51%, transparent 52%, transparent 100%)',
                        backgroundSize: '30px 30px'
                    }}></div>
                </div>
            </div>

            {/* Content Container */}
            <div className="container mx-auto px-6 relative">
                <div className="max-w-4xl mx-auto">
                    {/* Header Content */}
                    <div className="text-center">
                        <div className="inline-flex items-center gap-2 bg-amber-300/10 px-4 py-2 mb-8">
                            <Book className="text-amber-300" size={20} />
                            <span className="text-amber-300 text-sm tracking-wider uppercase">Legal Documentation</span>
                        </div>

                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white tracking-wider uppercase mb-8">
                            Terms & Conditions
                        </h1>

                        <p className="text-xl text-gray-400 tracking-wide leading-relaxed max-w-2xl mx-auto mb-12">
                            Please review our terms and conditions carefully. These terms govern your relationship with Cask International and use of our services.
                        </p>

                        {/* Quick Links */}
                        <div className="flex flex-wrap justify-center gap-4 mt-8">
                            <a href="#licensing" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Licensing</span>
                            </a>
                            <a href="#delivery" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Delivery</span>
                            </a>
                            <a href="#payments" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Payments</span>
                            </a>
                        </div>
                    </div>

                    {/* Important Notice Box */}
                    <div className="mt-12 p-6 bg-neutral-800/50 border border-amber-300/10">
                        <div className="flex items-start gap-4">
                            <AlertCircle className="text-amber-300 flex-shrink-0 mt-1"/>
                            <div>
                                <h3 className="text-white font-light mb-2 uppercase tracking-wider">Important
                                    Notice</h3>
                                <p className="text-gray-400 text-sm tracking-wide">
                                    These terms were last updated on December 5, 2024. By continuing to use our
                                    services, you agree to be bound by the current version of these terms.
                                </p>
                            </div>

                        </div>
                        <div className="flex items-start gap-4 mt-10">
                            <AlertTriangle className="text-amber-300 flex-shrink-0 mt-1"/>
                            <div>
                                <h3 className="text-white font-light mb-1">Age Restricted Content</h3>
                                <p className="text-sm text-gray-400">You must be 18 or older to use our services</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* Bottom Fade */}
            <div
                className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-neutral-900 to-transparent"></div>
        </section>
    );
};


const TermsAndConditions = () => {
    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
            {/* Hero Section */}
            {/*<section className="relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-amber-300/5 to-transparent"></div>
                <div className="container mx-auto px-6 py-24">
                    <div className="max-w-3xl">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white tracking-wider uppercase mb-8">
                            Terms & Conditions
                        </h1>
                        <p className="text-xl text-gray-400 tracking-wide leading-relaxed">
                            Please review our terms and conditions carefully. These terms govern your relationship with Cask International and use of our services.
                        </p>
                    </div>
                </div>
            </section>*/}
            <TermsHero />

            {/* Legal Notice Banner */}
            {/*<section className="border-y border-amber-300/10 bg-neutral-800/50">
                <div className="container mx-auto px-6 py-12">
                    <div className="flex items-center gap-8">
                        <div className="flex items-center gap-4">
                            <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center">
                                <AlertTriangle className="text-amber-300" />
                            </div>
                            <div>
                                <h3 className="text-white font-light mb-1">Age Restricted Content</h3>
                                <p className="text-sm text-gray-400">You must be 18 or older to use our services</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            {/* Main Content */}
            <section className="py-24">
                <div className="container mx-auto px-6">
                    <div className="max-w-4xl mx-auto">
                        {/* Key Points Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
                            <div className="p-8 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
                                <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center mb-6">
                                    <Shield className="text-amber-300" size={24} />
                                </div>
                                <h3 className="text-xl font-light text-white tracking-wider mb-4">Legal Compliance</h3>
                                <p className="text-gray-400 tracking-wide">Our terms ensure compliance with New Zealand alcohol distribution laws and regulations.</p>
                            </div>

                            <div className="p-8 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
                                <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center mb-6">
                                    <Scale className="text-amber-300" size={24} />
                                </div>
                                <h3 className="text-xl font-light text-white tracking-wider mb-4">Fair Business</h3>
                                <p className="text-gray-400 tracking-wide">We maintain transparent and fair business practices with all our partners and customers.</p>
                            </div>
                        </div>

                        {/* Terms Sections */}
                        <div className="space-y-16">
                            {/* Introduction */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">1. Introduction</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <p>By accessing and using Cask International's services, including our website, ordering system, and distribution services, you agree to be bound by these terms and conditions. These terms constitute a legally binding agreement between you and Cask International Limited.</p>
                                </div>
                            </div>

                            {/* Licensing Requirements */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">2. Licensing Requirements</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <p>2.1. Valid Licenses</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>All trade customers must maintain valid liquor licenses as required by New Zealand law.</li>
                                        <li>Licenses must be current and verifiable.</li>
                                        <li>Changes in license status must be reported immediately.</li>
                                    </ul>

                                    <p className="mt-6">2.2. Age Verification</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>All customers must be at least 18 years of age.</li>
                                        <li>Age verification is required for new accounts.</li>
                                        <li>We reserve the right to request additional verification at any time.</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Orders and Delivery */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">3. Orders and Delivery</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <p>3.1. Order Requirements</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Minimum order quantities apply based on account type.</li>
                                        <li>Orders must be placed through our official ordering system.</li>
                                        <li>All orders are subject to availability and confirmation.</li>
                                    </ul>

                                    <p className="mt-6">3.2. Delivery Terms</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Delivery schedules are subject to route planning and availability.</li>
                                        <li>An authorized person must be present to receive deliveries.</li>
                                        <li>Risk transfers to the customer upon delivery completion.</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Payment and Pricing */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">4. Payment and Pricing</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Payment terms are specified in individual account agreements.</li>
                                        <li>Prices are subject to change without prior notice.</li>
                                        <li>Late payments may incur additional charges.</li>
                                        <li>We reserve the right to modify credit terms based on payment history.</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Product Quality */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">5. Product Quality</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>All products are guaranteed authentic and properly stored.</li>
                                        <li>Quality claims must be reported within 24 hours of delivery.</li>
                                        <li>Returns are accepted only for quality issues or incorrect shipments.</li>
                                        <li>Photographic evidence may be required for quality claims.</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Termination */}
                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">6. Termination</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Either party may terminate the agreement with written notice.</li>
                                        <li>Immediate termination may occur for breach of terms.</li>
                                        <li>Outstanding payments remain due after termination.</li>
                                        <li>Inventory returns may be required upon termination.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Contact Section */}
                        <div className="mt-16 p-8 bg-neutral-800/50 border border-amber-300/10">
                            <h2 className="text-xl font-light text-white tracking-wider uppercase mb-6">Questions About Our Terms?</h2>
                            <p className="text-gray-400 tracking-wide mb-6">
                                Our team is here to help clarify any questions about our terms and conditions.
                            </p>
                            <div className="space-y-2">
                                <p className="text-gray-400">Email: legal@cask.co.nz</p>
                                <p className="text-gray-400">Phone: 02 777 999 77</p>
                                <p className="text-gray-400">Business Hours: Monday to Friday, 9am - 5pm</p>
                            </div>
                        </div>

                        {/* Last Updated */}
                        <div className="text-center mt-16">
                            <p className="text-sm text-gray-500 tracking-wider">Last Updated: December 5, 2024</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsAndConditions;