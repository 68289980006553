import React, {useState, useEffect} from 'react';
import {Search, Filter, ChevronDown, LogIn, SlidersHorizontal, X, ShoppingCart, AlertCircle} from 'lucide-react';
import { Link } from 'react-router-dom';

import { generateSlug } from './ProductDetail';
import {API_URL} from "./Settings.config";
// Alert Component
const Alert = ({children, onClose}) => (
    <div
        className="fixed top-16 md:top-24 right-4 left-4 md:left-auto md:w-96 z-50 bg-red-500/90 text-white p-4 rounded-none border border-red-600 animate-fadeIn">
        <div className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0"/>
            <div className="flex-1">{children}</div>
            {onClose && (
                <button onClick={onClose} className="text-white hover:text-red-200 transition-colors p-2">
                    <X size={18}/>
                </button>
            )}
        </div>
    </div>
);

// Mobile Filter Drawer Component
const FilterDrawer = ({isOpen, onClose, children}) => (
    <>
        {isOpen && (
            <div
                className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 transition-opacity duration-300"
                onClick={onClose}
            />
        )}
        <div
            className={`fixed bottom-0 left-0 right-0 bg-neutral-900 border-t border-amber-300/10 z-50 transition-transform duration-300 transform ${
                isOpen ? 'translate-y-0' : 'translate-y-full'
            }`}>
            <div className="flex justify-between items-center p-4 border-b border-amber-300/10">
                <h3 className="text-white font-light tracking-wider uppercase">Filters & Sort</h3>
                <button onClick={onClose} className="text-gray-400 hover:text-white">
                    <X size={20}/>
                </button>
            </div>
            <div className="p-4 max-h-[70vh] overflow-y-auto">
                {children}
            </div>
        </div>
    </>
);

// Product Card Component
const ProductCard = ({product}) => (
    <Link
        to={`/product/${generateSlug(product.name)}/${product.id}`}
        className="group relative overflow-hidden animate-fadeIn h-full"
    >
    <div className="group relative overflow-hidden animate-fadeIn h-full">
        <div
            className="bg-neutral-900/80 backdrop-blur-sm border border-amber-300/10 h-full transition-all duration-500 hover:border-amber-300/30 flex flex-col">
            <div className="relative aspect-[4/3] md:aspect-[3/4] overflow-hidden">
                <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-full object-cover transform transition-transform duration-700 group-hover:scale-105"
                />
                <div
                    className="absolute inset-0 bg-gradient-to-t from-neutral-900 via-neutral-900/20 to-transparent"></div>

                {product.stock <= 10 && (
                    <div className="absolute top-4 left-4 bg-red-500/90 px-3 py-1">
                        <span className="text-xs font-medium tracking-wider text-white uppercase">Low Stock</span>
                    </div>
                )}
            </div>

            <div className="p-4 md:p-6 flex-grow flex flex-col">
                <span className="text-amber-300/80 text-sm tracking-wider uppercase">{product.category}</span>
                <h3 className="text-lg font-light text-white tracking-wider mb-2 mt-2 line-clamp-2">{product.name}</h3>
                <div className="space-y-2 mb-6 flex-grow">
                    <p className="text-sm text-gray-400 tracking-wide">{product.volume}</p>
                    <p className="text-sm text-gray-400 tracking-wide">ABV: {product.alcPercentage}%</p>
                    <p className="text-sm text-gray-400 tracking-wide">SKU: {product.sku}</p>
                </div>

                <button
                    className="w-full flex items-center justify-center gap-2 py-3 border border-amber-300/20 text-amber-300 hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300">
                    <LogIn size={16}/>
                    <span className="text-sm tracking-wider uppercase">Login to see price</span>
                </button>
            </div>
        </div>
    </div>
    </Link>
);

const Pagination = ({currentPage, totalPages, onPageChange}) => {
    const getPageNumbers = () => {
        const pages = [];
        const maxVisible = 5; // Maximum visible page numbers

        if (totalPages <= maxVisible) {
            // Show all pages if total is less than max visible
            return Array.from({length: totalPages}, (_, i) => i + 1);
        }

        // Always show first page
        pages.push(1);

        let startPage = Math.max(2, currentPage - 1);
        let endPage = Math.min(totalPages - 1, currentPage + 1);

        // Adjust start and end page when current page is near edges
        if (currentPage <= 3) {
            endPage = Math.min(maxVisible - 1, totalPages - 1);
        } else if (currentPage >= totalPages - 2) {
            startPage = Math.max(2, totalPages - (maxVisible - 2));
        }

        // Add ellipsis and pages
        if (startPage > 2) {
            pages.push('...');
        }
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        if (endPage < totalPages - 1) {
            pages.push('...');
        }

        // Always show last page
        if (totalPages > 1) {
            pages.push(totalPages);
        }

        return pages;
    };

    return (
        <div className="mt-8 md:mt-12 flex flex-wrap justify-center items-center gap-2 px-4">
            {/* Previous button */}
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 md:px-4 py-2 border border-amber-300/20 text-amber-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300 text-sm"
            >
                Previous
            </button>

            {/* Page numbers */}
            <div className="flex gap-2">
                {getPageNumbers().map((page, index) => (
                    page === '...' ? (
                        <span
                            key={`ellipsis-${index}`}
                            className="px-3 py-2 text-gray-400"
                        >
                            {page}
                        </span>
                    ) : (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-3 py-2 border min-w-[40px] ${
                                currentPage === page
                                    ? 'bg-amber-300 text-neutral-900 border-amber-300'
                                    : 'border-amber-300/20 text-amber-300 hover:bg-amber-300 hover:text-neutral-900'
                            } transition-all duration-300 text-sm`}
                        >
                            {page}
                        </button>
                    )
                ))}
            </div>

            {/* Next button */}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 md:px-4 py-2 border border-amber-300/20 text-amber-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300 text-sm"
            >
                Next
            </button>
        </div>
    );
};

// Main Component
const ProductListing = () => {
    // State Management
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const host = API_URL

    // Fetch Products
    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch(host + '/invoice/website/all-products');
            if (!response.ok) throw new Error('Failed to fetch products');
            const data = await response.json();

            const formattedData = data.map(product => ({
                id: product.id,
                name: product.product_name,
                category: product.product_category,
                sku: product.product_sku,
                volume: `${product.quantity}ML`,
                alcPercentage: product.alc_percentage,
                stock: product.available_stock,
                description: product.description,
                image: product.extra_details && product.extra_details.website_image_url
                    ? host + `/images/${product.extra_details.website_image_url}`
                    : host + `/images/${product.image_url}`
            }));

            const uniqueCategories = [...new Set(data.map(product => product.product_category))];
            setCategories(uniqueCategories);
            setProducts(formattedData);
            setFilteredProducts(formattedData);
        } catch (err) {
            setError('Failed to load products. Please try again later.');
            console.error('Error fetching products:', err);
        } finally {
            setLoading(false);
        }
    };

    // Filter & Sort Products
    useEffect(() => {
        let result = [...products];

        if (selectedCategory) {
            result = result.filter(product =>
                product.category.toLowerCase() === selectedCategory.toLowerCase()
            );
        }

        if (searchQuery) {
            result = result.filter(product =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.sku.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        result.sort((a, b) => {
            switch (sortBy) {
                case 'name':
                    return a.name.localeCompare(b.name);
                case 'category':
                    return a.category.localeCompare(b.category);
                case 'stock':
                    return b.stock - a.stock;
                default:
                    return 0;
            }
        });

        setFilteredProducts(result);
        setCurrentPage(1);
    }, [searchQuery, selectedCategory, sortBy, products]);

    // Pagination
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const currentProducts = filteredProducts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Mobile Filters Component
    const MobileFilters = () => (
        <div className="space-y-6">
            <div className="space-y-4">
                <label className="block text-sm text-gray-400 uppercase tracking-wider">Category</label>
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white focus:outline-none focus:border-amber-300/30"
                >
                    <option value="">All Categories</option>
                    {categories.map((category) => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            <div className="space-y-4">
                <label className="block text-sm text-gray-400 uppercase tracking-wider">Sort By</label>
                <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="w-full px-4 py-3 bg-neutral-800 border border-amber-300/10 text-white focus:outline-none focus:border-amber-300/30"
                >
                    <option value="name">Sort by Name</option>
                    <option value="category">Sort by Category</option>
                    <option value="stock">Sort by Stock</option>
                </select>
            </div>

            <button
                onClick={() => setIsDrawerOpen(false)}
                className="w-full py-3 bg-amber-300 text-neutral-900 uppercase tracking-wider text-sm hover:bg-amber-400 transition-colors"
            >
                Apply Filters
            </button>
        </div>
    );

    // Main Render
    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
            {/* Alert */}
            {error && (
                <Alert onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            <div className="container mx-auto px-4 md:px-6 py-8 md:py-12">
                {/* Header */}
                <div className="text-center mb-8 md:mb-12">
                    <h1 className="text-3xl md:text-4xl font-light text-white tracking-wider uppercase mb-4">
                        Our Collection
                    </h1>
                    <p className="text-gray-400 tracking-wide max-w-2xl mx-auto text-sm md:text-base">
                        Explore our extensive collection of premium spirits, wines, and beverages
                    </p>
                </div>

                {/* Mobile Search and Filter Triggers */}
                <div className="lg:hidden mb-6 space-y-4">
                    <div className="relative">
                        <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20}/>
                        <input
                            type="text"
                            placeholder="Search products..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-12 pr-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                        />
                    </div>
                    <button
                        onClick={() => setIsDrawerOpen(true)}
                        className="w-full flex items-center justify-center gap-2 py-3 border border-amber-300/20 text-amber-300"
                    >
                        <SlidersHorizontal size={16}/>
                        <span className="text-sm tracking-wider uppercase">Filters & Sort</span>
                    </button>
                </div>

                {/* Desktop Filters and Search */}
                <div className="hidden lg:flex mb-12 items-center justify-between gap-6">
                    <div className="relative flex-1 max-w-lg">
                        <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20}/>
                        <input
                            type="text"
                            placeholder="Search by name, category, or SKU..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-12 pr-4 py-3 bg-neutral-800 border border-amber-300/10 text-white placeholder-gray-400 focus:outline-none focus:border-amber-300/30"
                        />
                    </div>

                    <div className="flex gap-4">
                        <div className="relative">
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="appearance-none px-4 py-3 pr-10 bg-neutral-800 border border-amber-300/10 text-white focus:outline-none focus:border-amber-300/30"
                            >
                                <option value="">All Categories</option>
                                {categories.map((category) => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={20}/>
                        </div>

                        <div className="relative">
                            <select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                                className="appearance-none px-4 py-3 pr-10 bg-neutral-800 border border-amber-300/10 text-white focus:outline-none focus:border-amber-300/30"
                            >
                                <option value="name">Sort by Name</option>
                                <option value="category">Sort by Category</option>
                                <option value="stock">Sort by Stock</option>
                            </select>
                            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={20}/>
                        </div>
                    </div>
                </div>

                {/* Products Grid */}
                {loading ? (
                    <div className="text-center py-12">
                        <div
                            className="w-12 h-12 border-4 border-amber-300/30 border-t-amber-300 rounded-full animate-spin mx-auto"></div>
                    </div>
                ) : filteredProducts.length === 0 ? (
                    <div className="text-center py-12">
                        <p className="text-gray-400 text-lg">No products found matching your criteria</p>
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
                            {currentProducts.map((product) => (
                                <ProductCard key={product.id} product={product}/>
                            ))}
                        </div>


                        {/* Pagination */}
                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        )}
                    </>
                )}

                {/* Products Count */}
                {!loading && filteredProducts.length > 0 && (
                    <div className="mt-6 text-center text-sm text-gray-400">
                        Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
                        {Math.min(currentPage * itemsPerPage, filteredProducts.length)} of{' '}
                        {filteredProducts.length} products
                    </div>
                )}
            </div>

            {/* Mobile Filters Drawer */}
            <FilterDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <MobileFilters/>
            </FilterDrawer>

            {/* Scroll to Top Button */}
            <button
                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                className={`fixed bottom-6 right-6 p-3 bg-amber-300 text-neutral-900 shadow-lg transition-all duration-300 transform hover:scale-110 ${
                    window.pageYOffset > 300 ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
            >
                <ChevronDown className="transform rotate-180" size={20}/>
            </button>

            {/* Animations */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes slideUp {
                    from {
                        transform: translateY(100%);
                    }
                    to {
                        transform: translateY(0);
                    }
                }

                @keyframes slideDown {
                    from {
                        transform: translateY(-100%);
                    }
                    to {
                        transform: translateY(0);
                    }
                }

                .animate-fadeIn {
                    animation: fadeIn 0.5s ease forwards;
                }

                .animate-slideUp {
                    animation: slideUp 0.3s ease forwards;
                }

                .animate-slideDown {
                    animation: slideDown 0.3s ease forwards;
                }

                /* Hide scrollbar for WebKit browsers */
                .scrollbar-hide::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for Firefox */
                .scrollbar-hide {
                    scrollbar-width: none;
                }

                /* Smooth scroll behavior */
                html {
                    scroll-behavior: smooth;
                }
            `}</style>
        </div>
    );
};


export default ProductListing;