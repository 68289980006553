import React, {useEffect, useState} from 'react';
import { LogIn, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import { generateSlug } from './ProductDetail';
import {API_URL} from "./Settings.config";

const ProductCard = ({ id, name, volume, image = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQJgq1KINtK7kC8zh2549k9A8voSay4ec-ww&s" }) => (
    <Link to={`/product/${generateSlug(name)}/${id}`} className="block">
        <div className="group relative overflow-hidden">
            {/* Product Card Container */}
            <div className="bg-neutral-900/80 backdrop-blur-sm border border-amber-300/10 h-full transition-all duration-500 hover:border-amber-300/30">
                {/* Image Container */}
                <div className="relative aspect-[3/4] overflow-hidden">
                    <img
                        src={image}
                        alt={name}
                        className="w-full h-full object-cover transform transition-transform duration-700 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-neutral-900 via-neutral-900/20 to-transparent"></div>

                    {/* Best Seller Badge */}
                    <div className="absolute top-4 right-4 flex items-center gap-1 bg-amber-300/90 px-3 py-1">
                        <Star size={14} className="text-neutral-900" />
                        <span className="text-xs font-medium tracking-wider text-neutral-900 uppercase">Best Seller</span>
                    </div>
                </div>

                {/* Product Info */}
                <div className="p-6">
                    <h3 className="text-lg font-light text-white tracking-wider mb-2">{name}</h3>
                    <p className="text-sm text-gray-400 tracking-wide mb-6">{volume}</p>

                    {/* Price Button */}
                    <button className="w-full flex items-center justify-center gap-2 py-3 border border-amber-300/20 text-amber-300 hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300">
                        <LogIn size={16} />
                        <span className="text-sm tracking-wider uppercase">Login to see price</span>
                    </button>
                </div>
            </div>
        </div>
    </Link>
);

const host = API_URL;
const url = host + "/invoice/top-discounted-products";

const BestSellers = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch(url)
            .then(response => response.json())
            .then(data => setProducts(data.map(product => ({
                id: product.id,
                name: product.product_name,
                volume: product.quantity + "ML",
                image: host + '/images/' + (product.extra_details?.website_image_url || product.image_url)
            }))))
            .catch(error => console.error('Error fetching products:', error));
    }, []);

    return (
        <section className="py-24 bg-neutral-900 relative overflow-hidden">
            {/* Decorative Background */}
            <div className="absolute inset-0 opacity-5">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-amber-300/20 via-transparent to-transparent"></div>
            </div>

            <div className="container mx-auto px-6 relative">
                {/* Section Header */}
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6">
                        Our Best Sellers
                    </h2>
                    <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                    <p className="text-gray-400 font-light tracking-wide max-w-2xl mx-auto">
                        Discover our most sought-after premium spirits, carefully selected from renowned distilleries worldwide.
                    </p>
                </div>

                {/* Products Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {products.map((product, index) => (
                        <ProductCard key={index} {...product} />
                    ))}
                </div>

                {/* View All Products CTA */}
                <div className="text-center mt-16">
                    <Link
                        to="/products"
                        className="inline-block border border-amber-300 text-amber-300 px-12 py-4 uppercase tracking-wider text-sm hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300"
                    >
                        View Full Collection
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BestSellers;