import React from 'react';
import { Crown, Thermometer, Network } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, index }) => (
    <div
        className="relative group"
        style={{
            transitionDelay: `${index * 100}ms`
        }}
    >
        {/* Card Content */}
        <div className="relative z-10 p-12 bg-neutral-900/80 backdrop-blur-md border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
            {/* Decorative Number */}
            <div className="absolute -top-8 right-8 text-[120px] font-light text-amber-300/5 select-none transition-opacity duration-500 group-hover:text-amber-300/10">
                {index + 1}
            </div>

            {/* Icon */}
            <div className="relative mb-8">
                <div className="w-16 h-16 flex items-center justify-center bg-amber-300/10 group-hover:bg-amber-300/20 transition-colors duration-500">
                    <Icon size={32} className="text-amber-300" />
                </div>
            </div>

            {/* Content */}
            <h3 className="text-xl font-light text-white tracking-wider uppercase mb-6">
                {title}
            </h3>
            <p className="text-gray-400 leading-relaxed tracking-wide">
                {description}
            </p>
        </div>

        {/* Hover Effect Background */}
        <div className="absolute inset-0 bg-gradient-to-b from-amber-300/0 to-amber-300/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
    </div>
);

const ServicesSection = () => {
    const services = [
        {
            icon: Crown,
            title: "Brand Management",
            description: "We work closely with our producers to understand more about the philosophy behind the products. We believe that our producers should share the same passion as we do as a company."
        },
        {
            icon: Thermometer,
            title: "Cold Chain Logistics",
            description: "Wherever possible we work with a cold chain supply network, from shipping in reefers to storage in temperature controlled warehouse. We believe quality should not be compromised."
        },
        {
            icon: Network,
            title: "Sales & Distribution",
            description: "We are affiliated with a wide network of qualified and reputable partners, covering on-trade and off-trade requirements."
        }
    ];

    return (
        <section className="py-24 relative overflow-hidden bg-neutral-900">
            {/* Background Decorative Elements */}
            <div className="absolute inset-0">
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>
                <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>
            </div>

            {/* Content Container */}
            <div className="container mx-auto px-6 relative">
                {/* Section Header */}
                <div className="text-center mb-20 max-w-3xl mx-auto">
                    <h2 className="text-3xl font-light tracking-wider text-white uppercase mb-6">
                        Excellence in Every Step
                    </h2>
                    <div className="w-24 h-px bg-amber-300/30 mx-auto mb-6"></div>
                    <p className="text-gray-400 tracking-wide">
                        Our commitment to quality extends beyond products to encompass every aspect of our operations,
                        ensuring excellence from source to service.
                    </p>
                </div>

                {/* Services Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            {...service}
                            index={index}
                        />
                    ))}
                </div>

                {/* Optional CTA */}
                <div className="text-center mt-16">
                    <a
                        href="/partner"
                        className="inline-block border border-amber-300 text-amber-300 px-8 py-3 uppercase tracking-wider text-sm hover:bg-amber-300 hover:text-neutral-900 transition-all duration-300"
                    >
                        Partner With Us
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;