import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock, User, ArrowRight, Eye, EyeOff, Loader, AlertCircle, CheckCircle2 } from 'lucide-react';
import {ANGULAR_URL, API_URL} from "./Settings.config";
import {jwtDecode} from "jwt-decode";
import {validateToken} from "./JWTTokenValidator";

// Alert component for showing status messages
const Alert = ({ type, message, onClose }) => {
    const colors = {
        error: 'bg-red-500/90 border-red-600',
        success: 'bg-green-500/90 border-green-600',
        info: 'bg-blue-500/90 border-blue-600'
    };

    return (
        <div className={`fixed top-16 md:top-24 right-4 left-4 md:left-auto md:w-96 z-50 ${colors[type]} text-white p-4 rounded-none border animate-fadeIn`}>
            <div className="flex items-center gap-2">
                {type === 'error' && <AlertCircle className="h-5 w-5 flex-shrink-0" />}
                {type === 'success' && <CheckCircle2 className="h-5 w-5 flex-shrink-0" />}
                <div className="flex-1">{message}</div>
            </div>
        </div>
    );
};

// Input field component with validation
const FormInput = ({ icon: Icon, type, placeholder, value, onChange, error }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className="space-y-1">
            <div className={`relative group transition-all duration-300 ${error ? 'shake' : ''}`}>
                <div className={`absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 transition-colors duration-300 ${isFocused ? 'text-amber-300' : ''}`}>
                    <Icon size={20} />
                </div>
                <input
                    type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                    className={`w-full pl-12 pr-4 py-3 bg-neutral-800 border ${
                        error ? 'border-red-500' : isFocused ? 'border-amber-300' : 'border-amber-300/10'
                    } text-white placeholder-gray-400 focus:outline-none transition-colors duration-300`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                {type === 'password' && (
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-amber-300 transition-colors duration-300"
                    >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                )}
            </div>
            {error && <p className="text-red-500 text-sm pl-1">{error}</p>}
        </div>
    );
};

// Login Component
export const Login =  () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({email: '', password: ''});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Basic validation
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.password) newErrors.password = 'Password is required';
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        // Simulated API call
        // make a post request to the api server with this email and password
        // if the user is authenticated, the server will send back a token
        // store the token in local storage
        // redirect the user to the dashboard

        setLoading(true);
        try {
            const response = await fetch(API_URL + '/auth/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                // console.log(response)
                const data = await response.json();
                throw new Error(data.message || 'Login failed');
            }

            const data = await response.json();
            localStorage.setItem('t', data.token);
            localStorage.setItem('r', data.rtoken);
            localStorage.setItem('as', data.as);

            setTimeout(() => {
                setLoading(false);
                setAlert({
                    type: 'success',
                    message: 'Login successful! Redirecting to Cask Order Management System...'
                });
                setTimeout(() => {
                    const redirectLocation =  ANGULAR_URL +"/auth/auto-login/" + data.token + '/' + data.rtoken + '/' + data.as
                    console.log(redirectLocation)
                         window.location.href = redirectLocation

                }, 1000);
            }, 1500);

        } catch (error) {
            setErrors({general: error.message});
            setLoading(false);
            setAlert({type: 'error', message: error.message});
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        const verifyToken = async () => {
            const verificationResult = await validateToken(localStorage.getItem('t'));
            if (verificationResult) {
                setAlert({
                    type: 'success',
                    message: 'We have successfully verified your login session! Redirecting to Cask Order Management System...'
                });
                setTimeout(() => {
                    window.location.href = ANGULAR_URL + '/auth/auto-login/' + localStorage.getItem('t') + '/' + localStorage.getItem('r');
                }, 1000);
            } else {
                localStorage.removeItem('t');
                localStorage.removeItem('r');
            }
        };

        verifyToken();
    }, []);


    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24 px-4">
            {alert && <Alert {...alert} />}

            <div className="container mx-auto max-w-md">
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-light text-white tracking-wider uppercase mb-4  mt-12">Welcome
                        Back</h1>
                    <p className="text-gray-400 tracking-wide">Sign in to access Cask International Smart Order
                        Management System</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <FormInput
                        icon={Mail}
                        type="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                        error={errors.email}
                    />

                    <FormInput
                        icon={Lock}
                        type="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                        error={errors.password}
                    />

                    <div className="flex items-center justify-between">
                        <label className="flex items-center gap-2 cursor-pointer group">
                            <input
                                type="checkbox"
                                className="w-4 h-4 border border-amber-300/30 bg-neutral-800 checked:bg-amber-300 checked:border-amber-300 focus:ring-0 focus:ring-offset-0"
                            />
                            <span className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">Remember me</span>
                        </label>

                        <a href="/forgot-password"
                           className="text-sm text-amber-300 hover:text-amber-400 transition-colors">
                            Forgot Password?
                        </a>
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-amber-300 text-neutral-900 py-3 flex items-center justify-center gap-2 hover:bg-amber-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed group"
                    >
                        {loading ? (
                            <Loader className="animate-spin" size={20}/>
                        ) : (
                            <>
                                <span className="tracking-wider uppercase">Sign In</span>
                                <ArrowRight className="transition-transform duration-300 group-hover:translate-x-1"
                                            size={20}/>
                            </>
                        )}
                    </button>
                </form>

                <div className="mt-8 text-center">
                    <p className="text-gray-400">
                        Don't have an account?{' '}
                        <a href="/signup" className="text-amber-300 hover:text-amber-400 transition-colors">
                            Sign Up
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

// Signup Component
export const Signup = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Validation
        const newErrors = {};
        if (!formData.fullName) newErrors.fullName = 'Full name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.password) newErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(API_URL + '/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: formData.fullName,
                    email: formData.email,
                    password: formData.password,
                    confirmPassword: formData.confirmPassword
                })
            });

            const data = await response.json();

            if (response.ok && !data.error) {
                setAlert({
                    type: 'success',
                    message: 'Account created successfully! Redirecting ...'
                });
                setTimeout(() => {
                    window.location.href = (ANGULAR_URL +'/auth/register/success');
                }, 2000);
            } else {
                throw new Error(data.message || 'Registration failed');
            }
        } catch (error) {
            setErrors({general: error.message});
            setAlert({
                type: 'error',
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24 px-4">
            {alert && <Alert {...alert} />}

            <div className="container mx-auto max-w-md">
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-light text-white tracking-wider uppercase mb-4  mt-12">Create Account</h1>
                    <p className="text-gray-400 tracking-wide">Join Cask International Smart Order Management System</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <FormInput
                        icon={User}
                        type="text"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                        error={errors.fullName}
                    />

                    <FormInput
                        icon={Mail}
                        type="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        error={errors.email}
                    />

                    <FormInput
                        icon={Lock}
                        type="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        error={errors.password}
                    />

                    <FormInput
                        icon={Lock}
                        type="password"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        error={errors.confirmPassword}
                    />

                    <div className="space-y-4">
                        <label className="flex items-start gap-2 cursor-pointer group">
                            <input
                                type="checkbox"
                                required
                                className="mt-1 w-4 h-4 border border-amber-300/30 bg-neutral-800 checked:bg-amber-300 checked:border-amber-300 focus:ring-0 focus:ring-offset-0"
                            />
                            <span className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">
                I agree to the <a href="/terms" className="text-amber-300 hover:text-amber-400">Terms of Service</a> and{' '}
                                <a href="/privacy" className="text-amber-300 hover:text-amber-400">Privacy Policy</a>
              </span>
                        </label>
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-amber-300 text-neutral-900 py-3 flex items-center justify-center gap-2 hover:bg-amber-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed group"
                    >
                        {loading ? (
                            <Loader className="animate-spin" size={20} />
                        ) : (
                            <>
                                <span className="tracking-wider uppercase">Create Account</span>
                                <ArrowRight className="transition-transform duration-300 group-hover:translate-x-1" size={20} />
                            </>
                        )}
                    </button>
                </form>

                <div className="mt-8 text-center">
                    <p className="text-gray-400">
                        Already have an account?{' '}
                        <a href="/login" className="text-amber-300 hover:text-amber-400 transition-colors">
                            Sign In
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default { Login, Signup };