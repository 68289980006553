import React from 'react';
import { Shield, Lock, Eye, FileText, AlertCircle, Book, Server, Mail } from 'lucide-react';

const PrivacyHero = () => {
    return (
        <section className="relative min-h-[50vh] flex items-center justify-center overflow-hidden">
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-b from-neutral-900 via-neutral-900/95 to-neutral-900"></div>
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>
                <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-amber-300/5 blur-3xl"></div>
                <div className="absolute inset-0 opacity-5">
                    <div className="absolute inset-0" style={{
                        backgroundImage: 'linear-gradient(45deg, transparent 0%, transparent 48%, rgba(245, 158, 11, 0.1) 49%, rgba(245, 158, 11, 0.1) 51%, transparent 52%, transparent 100%)',
                        backgroundSize: '30px 30px'
                    }}></div>
                </div>
            </div>

            <div className="container mx-auto px-6 relative">
                <div className="max-w-4xl mx-auto">
                    <div className="text-center">
                        <div className="inline-flex items-center gap-2 bg-amber-300/10 px-4 py-2 mb-8">
                            <Lock className="text-amber-300" size={20} />
                            <span className="text-amber-300 text-sm tracking-wider uppercase">Privacy & Security</span>
                        </div>

                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white tracking-wider uppercase mb-8">
                            Privacy Policy
                        </h1>

                        <p className="text-xl text-gray-400 tracking-wide leading-relaxed max-w-2xl mx-auto mb-12">
                            We are committed to protecting your privacy and ensuring the security of your personal information.
                        </p>

                        <div className="flex flex-wrap justify-center gap-4 mt-8">
                            <a href="#data-collection" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Data Collection</span>
                            </a>
                            <a href="#data-usage" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Data Usage</span>
                            </a>
                            <a href="#your-rights" className="group px-6 py-3 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-300">
                                <span className="text-gray-400 text-sm tracking-wider uppercase group-hover:text-amber-300">Your Rights</span>
                            </a>
                        </div>
                    </div>

                    <div className="mt-12 p-6 bg-neutral-800/50 border border-amber-300/10">
                        <div className="flex items-start gap-4">
                            <AlertCircle className="text-amber-300 flex-shrink-0 mt-1"/>
                            <div>
                                <h3 className="text-white font-light mb-2 uppercase tracking-wider">Last Updated</h3>
                                <p className="text-gray-400 text-sm tracking-wide">
                                    This privacy policy was last updated on December 5, 2024. We regularly review and update our privacy practices to ensure compliance with current legislation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-neutral-900 to-transparent"></div>
        </section>
    );
};

const PrivacyPolicy = () => {
    const privacyPoints = [
        {
            icon: Shield,
            title: "Data Protection",
            description: "We employ industry-standard security measures to protect your information."
        },
        {
            icon: Eye,
            title: "Transparency",
            description: "Clear communication about how we collect and use your data."
        },
        {
            icon: Server,
            title: "Secure Storage",
            description: "Your data is stored on secure servers with restricted access."
        }
    ];

    return (
        <div className="min-h-screen bg-neutral-900 pt-20 md:pt-24">
            <PrivacyHero />

            <section className="py-24">
                <div className="container mx-auto px-6">
                    <div className="max-w-4xl mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                            {privacyPoints.map((point, index) => (
                                <div key={index} className="p-8 bg-neutral-800/50 border border-amber-300/10 hover:border-amber-300/30 transition-all duration-500">
                                    <div className="w-12 h-12 bg-amber-300/10 flex items-center justify-center mb-6">
                                        <point.icon className="text-amber-300" size={24} />
                                    </div>
                                    <h3 className="text-xl font-light text-white tracking-wider mb-4">{point.title}</h3>
                                    <p className="text-gray-400 tracking-wide">{point.description}</p>
                                </div>
                            ))}
                        </div>

                        <div className="space-y-16">
                            <div id="data-collection">
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">1. Information We Collect</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <h3 className="text-xl text-white mb-4">1.1 Personal Information</h3>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Name and business contact information</li>
                                        <li>Liquor license details and business registration information</li>
                                        <li>Payment and transaction information</li>
                                        <li>Delivery addresses and contact details</li>
                                        <li>Account credentials and preferences</li>
                                    </ul>

                                    <h3 className="text-xl text-white mt-8 mb-4">1.2 Automatically Collected Information</h3>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Device and browser information</li>
                                        <li>IP address and location data</li>
                                        <li>Website usage statistics and preferences</li>
                                        <li>Order history and patterns</li>
                                    </ul>
                                </div>
                            </div>

                            <div id="data-usage">
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">2. How We Use Your Information</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Process and fulfill your orders</li>
                                        <li>Verify legal compliance and age requirements</li>
                                        <li>Communicate about orders and services</li>
                                        <li>Improve our products and services</li>
                                        <li>Personalize your experience</li>
                                        <li>Maintain account security</li>
                                        <li>Comply with legal obligations</li>
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">3. Data Sharing and Disclosure</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <p>We may share your information with:</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Delivery partners for order fulfillment</li>
                                        <li>Payment processors for transactions</li>
                                        <li>Legal authorities when required by law</li>
                                        <li>Service providers who assist our operations</li>
                                    </ul>
                                </div>
                            </div>

                            <div id="your-rights">
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">4. Your Rights and Choices</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Access your personal information</li>
                                        <li>Request corrections to your data</li>
                                        <li>Delete your account and data</li>
                                        <li>Opt-out of marketing communications</li>
                                        <li>Control cookie preferences</li>
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <h2 className="text-3xl font-light text-white tracking-wider uppercase mb-6">5. Data Security</h2>
                                <div className="space-y-4 text-gray-400 tracking-wide">
                                    <p>We implement various security measures including:</p>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li>Encryption of sensitive data</li>
                                        <li>Secure socket layer (SSL) technology</li>
                                        <li>Regular security assessments</li>
                                        <li>Access controls and authentication</li>
                                        <li>Employee training on data protection</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="mt-16 p-8 bg-neutral-800/50 border border-amber-300/10">
                            <h2 className="text-xl font-light text-white tracking-wider uppercase mb-6">Contact Our Privacy Team</h2>
                            <p className="text-gray-400 tracking-wide mb-6">
                                If you have any questions about our privacy practices or would like to exercise your privacy rights, please contact us:
                            </p>
                            <div className="space-y-2">
                                <p className="text-gray-400">Email: privacy@cask.co.nz</p>
                                <p className="text-gray-400">Phone: 02 777 999 77</p>
                                <p className="text-gray-400">Address: 40 Casey Avenue, Fairfield, Hamilton Waikato 3214</p>
                            </div>
                        </div>

                        <div className="text-center mt-16">
                            <p className="text-sm text-gray-500 tracking-wider">© {new Date().getFullYear()} Cask International. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PrivacyPolicy;